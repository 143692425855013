import React from "react";
import "./TitleBar.scss";
import { useState } from "react";
import { useEffect } from "react";
import useTranslate from "../../utils/Hooks/useTranslate";
import ArrowDownIcon from "../../assets/icons/Icon ionic-ios-arrow-down tan.svg";

export default function TitleBar({
  title,
  toggleButton,
  defaultToggle,
  top,
  className,
  boxClassName = "",
  children,
  titleEndElement,
  titleMore,
  toggleIcons = { hide: "", show: "", size: "" },
  hideOnEmpty,
  noPadding,
  titleContent,
}) {
  const { t } = useTranslate();

  const [inTitle, setTitle] = useState("");
  const [expandToggle, setExpandToggle] = useState(false);

  useEffect(() => {
    title && setTitle(title);
    defaultToggle === "show" && setExpandToggle(true);
    return () => {
      setTitle("");
      setExpandToggle(false);
    };
  }, [title, defaultToggle]);

  if (
    hideOnEmpty &&
    [...(Array.isArray(children) ? [...children] : [children])].every(
      (item) => item === false
    )
  )
    return null;

  return (
    <div id="TitleBar" className={className}>
      <div
        className={"header" + (top ? " top" : "")}
        onClick={() => setExpandToggle((s) => !s)}
      >
        <h3>
          {titleContent}
          {t(inTitle)}
          {titleMore}
        </h3>
        <div className="actions">
          {titleEndElement}
          {toggleButton &&
            (Object.values(toggleIcons).join("").length > 0 ? (
              <img
                src={expandToggle ? toggleIcons.show : toggleIcons.hide}
                alt=""
                style={{ width: toggleIcons.size }}
                onClick={(e) => {
                  e.stopPropagation();
                  setExpandToggle((s) => !s);
                }}
                className="toggleIcon"
              />
            ) : (
              <img
                className={
                  "toggleIcon" +
                  (expandToggle ? " tb__expand" : " tb__collapse")
                }
                onClick={(e) => {
                  e.stopPropagation();
                  setExpandToggle((s) => !s);
                }}
                src={ArrowDownIcon}
                alt=""
              />
            ))}
        </div>
      </div>
      {children && (
        <section
          className={
            "expandable__section " +
            boxClassName +
            (expandToggle || !toggleButton ? " tb__show" : " tb__hide") +
            `${noPadding ? " noPadding" : ""}`
          }
        >
          {children}
        </section>
      )}
    </div>
  );
}

{
  /*

<TitleBar
  title={'History'}
  toggleButton
  defaultToggle={'hide' || 'show'}
>
</TitleBar>

*/
}
