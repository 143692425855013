import moment from "moment";

export const modulePermission = () => {
  const permission = localStorage.getItem("permission");
  return permission;
};

export const moduleAccess = () => {
  const access = localStorage.getItem("access")
    ? JSON.parse(localStorage.getItem("access"))
    : [];
  return access;
};

export const modulePermissionKeys = () => {
  const permission_keys = localStorage.getItem("permission_keys")
    ? JSON.parse(localStorage.getItem("permission_keys"))
    : [];
  return permission_keys;
};

export const Capitalize = (text) => {
  return text ? text[0].toUpperCase() + text.slice(1) : "";
};

export const userType = (text) => {
  const user_type = localStorage.getItem("user_type") ?? "";
  return user_type;
};

export const additionalAccessSettings = () => {
  const additional_access_settings =
    localStorage.getItem("additional_access_settings") !== undefined &&
    localStorage.getItem("additional_access_settings")
      ? JSON.parse(localStorage.getItem("additional_access_settings"))
      : [];
  return additional_access_settings;
};

export const additionalAccessViews = () => {
  const additional_access_views = localStorage.getItem(
    "additional_access_views"
  )
    ? JSON.parse(localStorage.getItem("additional_access_views"))
    : [];
  return additional_access_views;
};

export const additionalAccessReports = () => {
  const additional_access_reports = localStorage.getItem(
    "additional_access_reports"
  )
    ? JSON.parse(localStorage.getItem("additional_access_reports"))
    : [];
  return additional_access_reports;
};

export const additionalAccessCreate = () => {
  const additional_access_create = localStorage.getItem(
    "additional_access_create"
  )
    ? JSON.parse(localStorage.getItem("additional_access_create"))
    : [];
  return additional_access_create;
};

export const dateFormatter = (date, isStart) => {
  if (!date || date?.length === 0) return "";
  else
    return (
      moment(date).format("YYYY-MM-DD hh:mm:ss")?.split(" ")[0] +
      (isStart ? " 00:00:00" : " 23:59:59")
    );
};

export const valueSplitFunction = (value) => {
  let temp = value?.split("_");
  let tempSplit = temp?.splice(1);
  let tempFinal = tempSplit?.join("");
  return tempFinal;
};
