export const config = {
  useSearch: false,
  spellcheck: false,
  enter: "div",
  defaultMode: "1",
  toolbarAdaptive: false,
  align: false,
  strikeThrogh: false,
  toolbarSticky: false,
  showCharsCounter: false,
  showWordsCounter: false,
  showXPathInStatusbar: false,
  askBeforePasteHTML: false,
  askBeforePasteFromWord: false,
  minHeight: 400,
  minWidth: null,
  defaultActionOnPasteFromWord: "insert_only_text",
  defaultActionOnPaste: "insert_only_text",
  buttons: [
    "bold",
    "italic",
    "underline",
    "paragraph",
    "redo",
    "undo",
    "ul",
    "ol",
    "left",
    "center",
    "right",
    "justify",
  ],
  editorCssClass: "alic",
  placeholder: "Start writing",
  controls: {
    paragraph: {
      list: {
        h1: "Heading 1",
        h2: "Heading 2",
        h3: "Heading 3",
        h4: "Heading 4",
        h5: "Heading 5",
        h6: "Heading 6",
      },
    },
    fontsize: {
      list: [
        "8",
        "9",
        "10",
        "11",
        "12",
        "14",
        "16",
        "18",
        "24",
        "30",
        "36",
        "48",
        "60",
        "72",
        "96",
        "100",
      ],
    },
    font: {
      command: "fontname",
      list: {
        "": "Default",
        "'Open Sans',sans-serif": "Open Sans",
        "Helvetica,sans-serif": "Helvetica",
        "Arial,Helvetica,sans-serif": "Arial",
        "Georgia,serif": "Georgia",
        "Impact,Charcoal,sans-serif": "Impact",
        "Tahoma,Geneva,sans-serif": "Tahoma",
        "'Times New Roman',Times,serif": "Times New Roman",
        "Verdana,Geneva,sans-serif": "Verdana",
      },
    },
  },
};

export const editorConfigEn = {
  buttons: [
    "bold",
    "italic",
    "underline",
    "undo",
    "ul",
    "ol",
    "indent",
    "outdent",
  ],
  placeholder: "type here",
  askBeforePasteHTML: false,
  // forcePlainText: true,
  enter: "div",
  defaultActionOnPasteFromWord: "insert_only_text",
  defaultActionOnPaste: "insert_only_text",
  sanitize: function (text) {
    // Remove HTML tags from text
    return text.replace(/<[^>]*>/g, "");
  },
  // enter: "br",
  showCharsCounter: false,
  showWordsCounter: false,
  showXPathInStatusbar: false,
};

export const editorConfigAr = {
  buttons: [
    "bold",
    "italic",
    "underline",
    "undo",
    "ul",
    "ol",
    "indent",
    "outdent",
  ],
  direction: "rtl",
  placeholder: "type here",
  askBeforePasteHTML: false,
  // forcePlainText: true,
  enter: "div",
  defaultActionOnPasteFromWord: "insert_only_text",
  defaultActionOnPaste: "insert_only_text",
  sanitize: function (text) {
    // Remove HTML tags from text
    return text.replace(/<[^>]*>/g, "");
  },
  // enter: "br",
  showCharsCounter: false,
  showWordsCounter: false,
  showXPathInStatusbar: false,
};
