import React, { useEffect, useState } from "react";
import "./Modal.scss";

import closeIcon from "./close1.svg";

export default function Modal({
  className,
  show,
  setShow,
  showCloseBtn,
  style,
  children,
  onHide,
}) {
  const [remove, setRemove] = useState(true);

  function handleParentClick(e) {
    if (e.target === e.currentTarget) {
      setShow(false);
      onHide && onHide();
    }
  }

  useEffect(() => {
    if (show === true) {
      setRemove(false);
    } else {
      setTimeout(() => {
        setRemove(true);
      }, 500);
    }
  }, [show]);

  return (
    <div
      id="Modal_backdrop"
      className={
        className +
        (show ? " display" : " display-none") +
        (remove ? " hide" : "")
      }
      onClick={handleParentClick}
    >
      <div id="Modal_canvas" style={style}>
        {showCloseBtn && <img src={closeIcon} alt="" />}
        {children}
      </div>
    </div>
  );
}
