import React, { useState } from "react";
import { HashRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RouteHandler from "./router/routes";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getDynamicTexts } from "./redux/actionCreator";

export const LangSwitch = React.createContext();

function App() {
  const dispatch = useDispatch();
  const [language, setCurrentLanguage] = useState("En");

  useEffect(() => {
    let lang = localStorage.getItem("lang");
    if (!lang) localStorage.setItem("lang", language);
    else setCurrentLanguage(lang);
  }, []);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(getDynamicTexts())
    }
  }, [])

  return (
    <div className="App">
      <LangSwitch.Provider value={{ setCurrentLanguage, language }}>
        <HashRouter>
          <ToastContainer />
          <RouteHandler />
        </HashRouter>
      </LangSwitch.Provider>
    </div>
  );
}

export default App;
