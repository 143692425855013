import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LangSwitch } from "../../App";
import {
  insertDynamicText,
} from "../../redux/actionCreator";
import { updateRedux } from "../../redux/commonReducer";

export default function useTranslate() {
  const WORDS = useSelector((s) => s.commonReducer.words);
  const AR_WORDS = useSelector((s) => s.commonReducer.arabic_words);
  const inAction =
    useSelector((s) => s.commonReducer.useTranslateInAction) || [];

  const { language } = useContext(LangSwitch);
  const dispatch = useDispatch();

  // const [inAction, setInAction] = useState([])

  function keyGetter() {
    return language === "ar" ? "_ar" : "_en";
  }

  function addWordHandler(value) {
    let obj = {
      title: value.toLowerCase().trim(),
      arabic_text: value.trim() + "_a",
      english_text: value.trim(),
      is_verified: false,
    };
    dispatch(
      insertDynamicText(obj, ({ status, message }) => {
        // status && successToast(message)
        // !status && errorToast(message)
      })
    );
  }

  function t(text) {
    // text.toLowerCase() === 'other allowance ( aed )' && console.log(text, WORDS?.[text?.toLowerCase() + keyGetter()], text?.toLowerCase() + keyGetter(), inAction)
    if (
      WORDS !== undefined &&
      AR_WORDS !== undefined &&
      text !== "" &&
      !text?.includes("undefined")
    ) {
      text = String(text);
      let word = WORDS?.[text?.toLowerCase() + keyGetter()] || text;
      if (
        WORDS?.[text?.toLowerCase() + keyGetter()] === undefined &&
        AR_WORDS?.[text?.toLowerCase()] === undefined &&
        !exceptWords?.includes(text)
      ) {
        if (!inAction?.includes(text?.toLowerCase())) {
          // console.log('New word :-', text)
          dispatch(
            updateRedux({
              key: "useTranslateInAction",
              value: [...inAction, text?.toLowerCase()],
            })
          );
          // addWordHandler(text)
        }
      }
      return word;
    }
    return text;
  }

  return { t, _lang: keyGetter(), lang: language };
}

// https://tsd.shtdevops.xyz/ecom-full-crm/hr/deleteUnverifiedTexts

const exceptWords = [
  "* Select Certificate_a",
  "* Select Certificate Language_a",
  "* Enter Addressee_a",
  "* Comments_a",
  "* undefined",
  "label",
  "DD/MM/YYYY",
  "dd/mm/yyyy	",
  "Bank 1",
  "Bank 2",
  "Bank 3",
  "Bank 4",
  "* category_a",
  "* comments_a",
  "* details_a",
  "* enter addressee_a",
  "* quantity required",
  "* quantity required_a",
  "* reason to purchase",
  "* select certificate language_a",
  "* select certificate_a",
  "* sub category_a",
  "* undefined",
  "0",
  "✖",
  "عربي",
  "?are you sure to",
];
