import React, { useEffect, useState } from "react";
import useFilter from "../../utils/Hooks/useFilter";
import PageHeader from "../../commonComponents/PageHeader/PageHeader";
import Title from "../../commonComponents/Title/Title";
import BreadCrump from "../../commonComponents/Breadcrump/Breadcrump";
import Button from "../../commonComponents/Button/Button";
import EntriesCounter from "../../commonComponents/EntriesCounter/EntriesCounter";
import Input from "../../commonComponents/Input/Input";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import Table from "../../commonComponents/Table/Table";
import useTranslate from "../../utils/Hooks/useTranslate";
import GenericSearchIcon from "../../assets/icons/Search1.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  exportSurveyAnsweredQuestionAndUsers,
  surveyAnsweredUsers,
} from "../../redux/actionCreator";
import {
  detail_survey_loading,
  survey_answer_details,
  survey_answer_pages,
} from "../../redux/commonReducer";
import FooterButtons from "../../commonComponents/FooterButtons/FooterButtons";
import BackIcon from "../../assets/icons/Back.svg";
import UploadIcon from "../../assets/icons/upload_export.svg";

export default function SurveyAnswerDetails() {
  const { t } = useTranslate();
  const { surveyId, questionId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const lang = localStorage.getItem("lang");

  const details = useSelector(survey_answer_details);
  const loading = useSelector(detail_survey_loading);
  const pageCount = useSelector(survey_answer_pages);

  const initialFilter = {
    page: 0,
    perPage: 10,
    sortColumn: "updated_at",
    sortValue: -1,
    date_from: "",
    date_to: "",
    keyword: "",
  };

  const [data, setData] = useState([]);

  const [filterData, setFilterData, F_navigate] = useFilter({
    initialFilter,
    dataFetch: (filter) => {
      getSearchDataHandler(filter);
    },
    dependency: [],
  });

  function filterHandler(key, value) {
    let filter;
    switch (key) {
      case "searchBox":
        filter = { ...filterData, ...value, page: 0 };
        break;
      case "clearSearch":
        filter = initialFilter;
        break;
      case "keyword":
        filter = { ...filterData, keyword: value, page: 0 };
        break;
      case "sort":
        filter = {
          ...filterData,
          sortColumn: value.key,
          sortValue: value.value,
        };
        break;
      case "page":
        filter = { ...filterData, page: value };
        break;
      case "entries":
        filter = { ...filterData, page: 0, perPage: parseInt(value) };
        break;
      default:
    }

    if (!filter) return;
    setFilterData(filter);
    getSearchDataHandler(filter);
  }

  useEffect(() => {
    setData([]);
    if (details?.answered_users) {
      details?.answered_users?.forEach((item, i) => {
        setData((s) => [
          ...s,
          {
            employee_id: item?.user?.[0]?.employee_id,
            employee_name: item?.user?.[0]?.name,
            department: item?.user?.[0]?.department?.[0]?.department_name ?? (
              <span className="mr-5">--</span>
            ),
            section: item?.user?.[0]?.section[0]?.section_name ?? (
              <span className="mr-5">--</span>
            ),
            designation: item?.user?.[0].designation?.[0]?.designation_name || (
              <span className="mr-5">--</span>
            ),
            answer: item?.answer?.length
              ? item?.answer?.map((ele) => {
                  return (
                    <p>
                      {lang === "en"
                        ? ele?.options_title
                        : ele?.options_title_ar}
                    </p>
                  );
                })
              : "",
            comment: item?.comment ?? "--",
          },
        ]);
      });
    }
  }, [details?.answered_users, lang]);

  const getSearchDataHandler = (filter) => {
    let formData = new FormData();
    formData.append("id", surveyId);
    formData.append("question_id", questionId);
    formData.append("page", filter.page);
    formData.append("perPage", filter.perPage);
    formData.append("sort_order", filter.sortValue);
    formData.append("sort_key", filter.sortColumn);
    formData.append("keyword", filter?.keyword?.trim() ?? "");
    dispatch(surveyAnsweredUsers(formData));
  };

  const handleExport = () => {
    let formData = new FormData();
    formData.append("id", surveyId);
    formData.append("question_id", questionId);
    formData.append("page", filterData.page);
    formData.append("perPage", filterData.perPage);
    formData.append("sort_order", filterData.sortValue);
    formData.append("sort_key", filterData.sortColumn);
    formData.append("keyword", filterData?.keyword?.trim() ?? "");
    dispatch(exportSurveyAnsweredQuestionAndUsers(formData));
  };

  return (
    <div id="survey_listing">
      <section className="mb-4">
        <PageHeader>
          <Title content="View Survey Answer Details" />
          <BreadCrump
            content={[
              {
                name: "View Survey Answer Details",
              },
              {
                name: "View Survey Details",
                path: `/survey/view-questions/${surveyId}`,
              },
              {
                name: "Survey",
                path: "/survey/survey-list",
              },
              {
                name: "Home",
                path: "/",
              },
            ]}
          />
        </PageHeader>
      </section>

      <section className="mb-4">
        <div id="SearchBox">
          <div className={"inputs mt-4"}>
            {loading ? (
              <></>
            ) : (
              <div>
                <p dir="ltr">
                  {`${
                    lang == "en"
                      ? details?.question?.question
                      : details?.question?.question_ar
                  }: ${t("Question")}`}
                </p>
                <p dir="ltr">
                  {`${
                    details?.question?.type === "checkbox"
                      ? "Checkbox"
                      : "Radio Button"
                  }: ${t("Type")}`}
                </p>
              </div>
            )}
            <Button
              className="css-btn-esc4 export_btn"
              iconWidth={12}
              content={"Export"}
              icon={UploadIcon}
              reverse
              onClick={() => handleExport()}
            />
          </div>
        </div>
      </section>
      {loading ? (
        <></>
      ) : (
        <section className="table">
          <Table
            created_at
            className={"css-tbl-esc2"}
            titles={[
              {
                title: "Answer",
                key: "answer",
                width: "50%",
              },
              {
                title: "Count",
                key: "count",
                width: "50%",
              },
            ]}
            content={details?.answers?.map((ele, i) => {
              return {
                answer:
                  lang === "en"
                    ? ele?.answer?.options_title
                    : ele?.answer?.options_title_ar,
                count: ele?.count,
              };
            })}
          />
        </section>
      )}
      <section className="d-flex justify-content-between mb-4">
        <EntriesCounter
          value={filterData.perPage}
          onChange={(e) => {
            filterHandler("entries", e);
          }}
        />
        <Input
          value={filterData.keyword}
          type="text"
          className="css-inp-esc2 search"
          icon={GenericSearchIcon}
          placeholder={t("Generic Search")}
          reverse
          width="300px"
          field="keyword"
          showCloseBtn
          onChange={(e) => !e && filterHandler("keyword", e)}
          onSubmit={(e) => {
            filterHandler("keyword", e);
          }}
          onIconClick={(e) => {
            filterHandler("keyword", e);
          }}
        />
      </section>
      {loading ? (
        <LoadingSpinner height={"200px"} />
      ) : (
        <section className="table">
          <Table
            created_at
            className={"css-tbl-esc2"}
            titles={[
              {
                title: "Employee ID",
                key: "employee_id",
                sort: true,
                width: "11%",
              },
              {
                title: "Employee Name",
                key: "employee_name",
                sort: true,
                width: "100px",
              },
              {
                title: "Department",
                key: "department",
                sort: true,
                width: "110px",
              },
              {
                title: "Section",
                key: "section",
                sort: true,
                width: "90px",
              },
              {
                title: "Designation",
                key: "designation",
                sort: true,
                width: "90px",
              },
              {
                title: "Submitted Answer",
                key: "answer",
                sort: true,
                width: "90px",
              },
              {
                title: "Comment",
                key: "comment",
                sort: true,
                width: "90px",
              },
            ]}
            content={data}
            onSort={(e) => filterHandler("sort", e)}
            page={filterData.page}
            pagesCount={pageCount}
            onPageChange={(e) => {
              filterHandler("page", e.selected);
            }}
            sortValue={{
              key: filterData.sortColumn,
              value: filterData.sortValue,
            }}
          />
          <FooterButtons>
            <Button
              right
              className="css-btn-esc3"
              content="Back"
              icon={BackIcon}
              reverse
              iconWidth="15px"
              onClick={() => navigate(`/survey/view-questions/${surveyId}`)}
            />
          </FooterButtons>
        </section>
      )}
    </div>
  );
}
