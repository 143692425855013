import React, { useEffect, useState } from "react";
import useFilter from "../../utils/Hooks/useFilter";
import PageHeader from "../../commonComponents/PageHeader/PageHeader";
import Title from "../../commonComponents/Title/Title";
import BreadCrump from "../../commonComponents/Breadcrump/Breadcrump";
import Button from "../../commonComponents/Button/Button";
import EntriesCounter from "../../commonComponents/EntriesCounter/EntriesCounter";
import Input from "../../commonComponents/Input/Input";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import Table from "../../commonComponents/Table/Table";
import useTranslate from "../../utils/Hooks/useTranslate";
import GenericSearchIcon from "../../assets/icons/Search1.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  exportEventsDetails,
  getEventApplicants,
} from "../../redux/actionCreator";
import {
  events_applicants_details,
  events_applicants_page_status,
  events_applicants_pages,
  events_loader,
} from "../../redux/commonReducer";
import FooterButtons from "../../commonComponents/FooterButtons/FooterButtons";
import BackIcon from "../../assets/icons/Back.svg";
import UploadIcon from "../../assets/icons/upload_export.svg";

export default function EventsApplicants() {
  const { t } = useTranslate();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const lang = localStorage.getItem("lang");

  const details = useSelector(events_applicants_details);
  const loading = useSelector(events_loader);
  const pageCount = useSelector(events_applicants_pages);
  const pageStatus = useSelector(events_applicants_page_status);

  const initialFilter = {
    page: 0,
    perPage: 10,
    sortColumn: "updated_at",
    sortValue: -1,
    date_from: "",
    date_to: "",
    keyword: "",
  };

  const [data, setData] = useState([]);

  const [filterData, setFilterData, F_navigate] = useFilter({
    initialFilter,
    dataFetch: (filter) => {
      getSearchDataHandler(filter);
    },
    dependency: [],
  });

  function filterHandler(key, value) {
    let filter;
    switch (key) {
      case "searchBox":
        filter = { ...filterData, ...value, page: 0 };
        break;
      case "clearSearch":
        filter = initialFilter;
        break;
      case "keyword":
        filter = { ...filterData, keyword: value, page: 0 };
        break;
      case "sort":
        filter = {
          ...filterData,
          sortColumn: value.key,
          sortValue: value.value,
        };
        break;
      case "page":
        filter = { ...filterData, page: value };
        break;
      case "entries":
        filter = { ...filterData, page: 0, perPage: parseInt(value) };
        break;
      default:
    }

    if (!filter) return;
    setFilterData(filter);
    getSearchDataHandler(filter);
  }

  useEffect(() => {
    setData([]);
    if (details?.applicantsData) {
      details?.applicantsData?.forEach((item, i) => {
        setData((s) => [
          ...s,
          {
            employee_id: item?.employee_id,
            employee_name: item?.name,
            department: item?.department?.[0]?.department_name ?? (
              <span className="mr-5">--</span>
            ),
            section: item?.section[0]?.section_name ?? (
              <span className="mr-5">--</span>
            ),
            designation: item.designation?.[0]?.designation_name || (
              <span className="mr-5">--</span>
            ),
          },
        ]);
      });
    }
  }, [details?.applicantsData, lang]);

  const getSearchDataHandler = (filter) => {
    let value = {
      id: id,
      page: filter.page,
      perPage: filter.perPage,
      sort_order: filter.sortValue,
      sort_key: filter.sortColumn,
      keyword: filter?.keyword?.trim() ?? "",
    };
    dispatch(getEventApplicants(value));
  };

  const handleExport = () => {
    dispatch(exportEventsDetails(id));
  };

  return (
    <div id="events_listing">
      <section className="mb-4">
        <PageHeader>
          <Title content="View Events Applicants" />
          <BreadCrump
            content={[
              {
                name: "View Applicants",
              },
              {
                name: "Events",
                path: "/events/events-list",
              },
              {
                name: "Home",
                path: "/",
              },
            ]}
          />
        </PageHeader>
      </section>

      <section className="mb-4">
        <div id="SearchBox">
          <div className={"inputs mt-4"}>
            {loading ? (
              <></>
            ) : (
              <p dir="ltr">
                {`${lang == "en" ? details?.title : details?.title_ar}: Title`}
              </p>
            )}
            <Button
              className="css-btn-esc4 export_btn"
              iconWidth={12}
              content={"Export"}
              icon={UploadIcon}
              reverse
              onClick={() => handleExport()}
            />
          </div>
        </div>
      </section>
      <section className="d-flex justify-content-between mb-4">
        <EntriesCounter
          value={filterData.perPage}
          onChange={(e) => {
            filterHandler("entries", e);
          }}
        />
        <Input
          value={filterData.keyword}
          type="text"
          className="css-inp-esc2 search"
          icon={GenericSearchIcon}
          placeholder={t("Generic Search")}
          reverse
          width="300px"
          field="keyword"
          showCloseBtn
          onChange={(e) => !e && filterHandler("keyword", e)}
          onSubmit={(e) => {
            filterHandler("keyword", e);
          }}
          onIconClick={(e) => {
            filterHandler("keyword", e);
          }}
        />
      </section>
      {loading ? (
        <LoadingSpinner height={"200px"} />
      ) : (
        <section className="table">
          <Table
            created_at
            className={"css-tbl-esc2"}
            titles={[
              {
                title: "Employee ID",
                key: "employee_id",
                sort: true,
                width: "11%",
              },
              {
                title: "Employee Name",
                key: "employee_name",
                sort: true,
                width: "100px",
              },
              {
                title: "Department",
                key: "department",
                sort: true,
                width: "110px",
              },
              {
                title: "Section",
                key: "section",
                sort: true,
                width: "90px",
              },
              {
                title: "Designation",
                key: "designation",
                sort: true,
                width: "90px",
              },
            ]}
            content={data}
            onSort={(e) => filterHandler("sort", e)}
            page={filterData.page}
            pagesCount={pageCount}
            pageStatus={pageStatus}
            onPageChange={(e) => {
              filterHandler("page", e.selected);
            }}
            sortValue={{
              key: filterData.sortColumn,
              value: filterData.sortValue,
            }}
          />
          <FooterButtons>
            <Button
              right
              className="css-btn-esc3"
              content="Back"
              icon={BackIcon}
              reverse
              iconWidth="15px"
              onClick={() => navigate(-1)}
            />
          </FooterButtons>
        </section>
      )}
    </div>
  );
}
