import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Title from "../../commonComponents/Title/Title";
import Breadcrump from "../../commonComponents/Breadcrump/Breadcrump";
import SearchBox from "../../commonComponents/SearchBox/SearchBox";
import EntriesCounter from "../../commonComponents/EntriesCounter/EntriesCounter";
import Input from "../../commonComponents/Input/Input";
import Table from "../../commonComponents/Table/Table";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import useTranslate from "../../utils/Hooks/useTranslate";
import PageHeader from "../../commonComponents/PageHeader/PageHeader";
import useFilter from "../../utils/Hooks/useFilter";
import GenericSearchImage from "../../assets/icons/Search1.svg";
import {
  getAllUsers,
  getAllUsersWithPagination,
  getDepartments,
  getDesignations,
  getSection,
} from "../../redux/actionCreator";
import {
  all_users_list,
  all_users_list_page_status,
  all_users_list_pages,
  all_users_list_wo_pagination,
  department_List,
  designations_list,
  section_details,
  updateRedux,
  users_list_loading,
} from "../../redux/commonReducer";

function ManageUsers() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslate();
  const lang = localStorage.getItem("lang");

  const allUsersList = useSelector(all_users_list_wo_pagination);
  const loading = useSelector(users_list_loading);
  const pageCount = useSelector(all_users_list_pages);
  const pageStatus = useSelector(all_users_list_page_status);
  const departmentList = useSelector(department_List);
  const userList = useSelector(all_users_list);
  const sectionList = useSelector(section_details);
  const designationList = useSelector(designations_list);

  const initialFilter = {
    department: "",
    section: "",
    employee: "",
    role: "",
    user_active: "",
    keyword: "",
    page: 0,
    perPage: 10,
    sortColumn: "createdAt",
    sortValue: -1,
  };

  const [filterData, setFilterData, F_navigate] = useFilter({
    initialFilter,
    dataFetch: (filter) => {
      getSearchDataHandler(filter);
    },
  });

  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(updateRedux({ key: "section_details", value: [] }));
    dispatch(getDepartments());
    dispatch(getDesignations());
    let formData = new FormData();
    formData.append("for_filter", 1);
    dispatch(getAllUsers(formData));
  }, []);

  const getSearchDataHandler = (filter) => {
    let formData = new FormData();
    formData.append("department", filter?.department?._id || "");
    formData.append("section", filter?.section?._id || "");
    formData.append("designation", filter?.designation?._id || "");
    formData.append("user", filter?.employee?.name || "");
    formData.append("role", filter?.role?.key || "");
    formData.append("keyword", filter?.keyword.trim() || "");
    formData.append("user_active", filter?.user_active?.value || "");
    formData.append("page", filter?.page || 0);
    formData.append("perPage", filter?.perPage || 0);
    formData.append("sort_key", filter?.sortColumn || "");
    formData.append("sort_order", filter?.sortValue);
    console.log(formData, "111111");
    dispatch(getAllUsersWithPagination(formData));
  };

  const tableHeaders = [
    {
      title: "Employee ID",
      key: "employee_id",
      width: "120px",
      sort: true,
    },
    { title: "Employee Name", key: "employee_name", sort: true },
    { title: "Department", key: "department", sort: true },
    { title: "Section", key: "section", sort: true, dir: "ltr" },
    {
      title: "Designation",
      key: "designation",
      sort: true,
      dir: "ltr",
    },
    { title: "Email", key: "email", sort: true, dir: "ltr" },
    { title: "Phone", key: "phone", sort: true },
    {
      title: "Status",
      key: "user_active",
      sort: true,
      width: "100px",
    },
    // { title: "Action", key: "action", width: "100px" },
  ];

  function searchBoxChangeHandler(value, key, setValues, values) {
    if (key === "department") {
      setValues((s) => ({ ...s, employee: "", section: "", designation: "" }));
      dispatch(updateRedux({ key: "section_details", value: [] }));
      dispatch(updateRedux({ key: "designations_list", value: [] }));
      dispatch(updateRedux({ key: "all_hr_users_list", value: [] }));
      value._id && dispatch(getSection(value._id));
      let formData = new FormData();
      formData.append("department", value?._id ?? "");
      formData.append("section", "");
      dispatch(getDesignations(formData));
      formData.append("for_filter", 1);
      dispatch(getAllUsers(formData));
    }
    if (key === "section") {
      setValues((s) => ({ ...s, designation: "", employee: "" }));
      dispatch(updateRedux({ key: "designations_list", value: [] }));
      dispatch(updateRedux({ key: "all_hr_users_list", value: [] }));
      let formData = new FormData();
      formData.append("department", values?.department?._id ?? "");
      formData.append("section", value?._id ?? "");
      dispatch(getDesignations(formData));
      formData.append("for_filter", 1);
      dispatch(getAllUsers(formData));
    }
    if (key === "designation") {
      setValues((s) => ({ ...s, employee: "" }));
      dispatch(updateRedux({ key: "all_hr_users_list", value: [] }));
      let formData = new FormData();
      formData.append("department", values?.department?._id ?? "");
      formData.append("section", values?.section?._id ?? "");
      formData.append("designation", value?._id ?? "");
      formData.append("for_filter", 1);
      dispatch(getAllUsers(formData));
    }
  }

  function filterHandler(key, value) {
    let filter;
    switch (key) {
      case "searchBox":
        filter = { ...filterData, ...value, page: 0 };
        break;
      case "clearSearch":
        filter = initialFilter;
        break;
      case "keyword":
        filter = { ...filterData, keyword: value, page: 0 };
        break;
      case "sort":
        filter = {
          ...filterData,
          sortColumn: value.key,
          sortValue: value.value,
        };
        break;
      case "page":
        filter = { ...filterData, page: value };
        break;
      case "entries":
        filter = { ...filterData, page: 0, perPage: parseInt(value) };
        break;
      default:
    }
    if (!filter) return;
    setFilterData(filter);
    getSearchDataHandler(filter);
  }

  useEffect(() => {
    setData([]);
    if (userList) {
      userList?.forEach((item, i) => {
        setData((s) => [
          ...s,
          {
            employee_id: item?.employee_id,
            employee_name: item?.name,
            department:
              lang === "en"
                ? item?.department?.department_name ?? "--"
                : item?.department?.department_name_ar ?? "--",
            designation: item?.designation?.[0]?.designation_name || "",
            section: item?.section?.length
              ? lang === "en"
                ? item?.section[0].section_name
                : item?.section[0].section_name_ar
              : "--",
            email: item?.email,
            phone: item?.phone,
            user_active: item?.user_active ? "Active" : "Inactive",
            // action: (
            //   <Actions
            //     buttons={{ view: LogImage }}
            //     onView={() =>
            //       F_navigate(`/hr-module/admin/users/user-log/${item?._id}`)
            //     }
            //   />
            // ),
          },
        ]);
      });
    }
  }, [userList, lang]);

  const onSyncHandler = () => {
    // dispatch(
    //   syncAd(() => {
    //     setFilterData(initialFilter);
    //     getSearchDataHandler(initialFilter);
    //   })
    // );
  };

  const clearEmployeeList = () => {
    let formData = new FormData();
    formData.append("department", "");
    formData.append("section", "");
    dispatch(getDesignations(formData));
    formData.append("for_filter", 1);
    dispatch(getAllUsers(formData));
    dispatch(updateRedux({ key: "section_details", value: [] }));
  };

  return (
    <div id="HumanResource_admin">
      <>
        <section className="mb-4">
          <PageHeader>
            <Title content="Users" />
            <Breadcrump
              content={[
                { name: "Users", path: "/hr-module/admin/users" },
                { name: "Home", path: "/admin" },
              ]}
            />
            {/* <Button
              className="css-btn-esc1"
              width={150}
              content="Sync Users"
              icon={SyncImage}
              reverse
              onClick={() => onSyncHandler()}
            /> */}
          </PageHeader>
        </section>
        <section className="mb-4">
          <SearchBox
            elements={[
              {
                label: "Department",
                key: "department",
                type: "select",
                className: "css-slc-esc2",
                placeholder: "Select Department",
                width: "200px",
              },
              {
                label: "Section",
                key: "section",
                type: "select",
                className: "css-slc-esc2",
                placeholder: "Select Section",
                width: "200px",
              },
              {
                label: "Designation",
                key: "designation",
                type: "select",
                className: "css-slc-esc2",
                placeholder: "Select",
                width: "200px",
              },
              {
                label: "Employee",
                key: "employee",
                type: "select",
                className: "css-slc-esc2",
                placeholder: "Select User",
                width: "200px",
              },
              {
                label: "Status",
                key: "user_active",
                type: "select",
                className: "css-slc-esc2",
                placeholder: "Select Status",
                doTranslate: true,
              },
            ]}
            options={{
              department: {
                options: departmentList,
                value: filterData?.department,
                labelSetter: (option) =>
                  lang === "en"
                    ? option?.department_name
                    : option?.department_name_ar,
                valueSetter: (option) => option._id,
              },
              section: {
                options: sectionList,
                value: filterData?.section,
                labelSetter: (option) =>
                  lang === "en"
                    ? option?.section_name
                    : option?.section_name_ar,
                valueSetter: (option) => option._id,
              },
              designation: {
                options: designationList,
                labelSetter: (option) => t(option.designation_name),
                valueSetter: (option) => option._id,
                value: filterData?.designation,
              },
              employee: {
                options: allUsersList,
                labelSetter: (option) => option.name,
                valueSetter: (option) => option._id,
                value: filterData?.employee,
              },
              user_active: {
                options: [
                  { value: "1", label: "Active" },
                  { value: "0", label: "Inactive" },
                ],
                labelSetter: (option) => option.label,
                valueSetter: (option) => option.value,
                value: filterData?.user_active,
              },
            }}
            onSearch={(values) => {
              filterHandler("searchBox", values);
            }}
            onClear={() => {
              filterHandler("clearSearch");
              clearEmployeeList();
            }}
            onChange={searchBoxChangeHandler}
            dependency={[filterData]}
          />
        </section>
        <section className="d-flex justify-content-between mb-4">
          <EntriesCounter
            value={filterData.perPage}
            onChange={(e) => {
              filterHandler("entries", e);
            }}
          />
          <Input
            value={filterData?.keyword}
            type="text"
            className="css-inp-esc2 search"
            icon={GenericSearchImage}
            placeholder={t("Generic Search")}
            reverse
            width="300px"
            field="keyword"
            onSubmit={(value) => {
              filterHandler("keyword", value);
            }}
            onIconClick={(value) => {
              filterHandler("keyword", value);
            }}
            onChange={(value) => !value && filterHandler("keyword", value)}
            showCloseBtn
          />
        </section>
        {loading ? (
          <LoadingSpinner height="600px" />
        ) : (
          <section className="table">
            <Table
              className={"css-tbl-esc2"}
              titles={tableHeaders}
              content={data}
              onSort={(e) => filterHandler("sort", e)}
              sortValue={{
                key: filterData.sortColumn,
                value: filterData.sortValue,
              }}
              page={filterData.page}
              pagesCount={pageCount}
              pageStatus={pageStatus}
              onPageChange={(e) => {
                filterHandler("page", e.selected);
              }}
            />
          </section>
        )}
      </>
    </div>
  );
}

export default ManageUsers;
