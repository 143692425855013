import React, { useEffect, useState } from "react";
import useFilter from "../../utils/Hooks/useFilter";
import PageHeader from "../../commonComponents/PageHeader/PageHeader";
import Title from "../../commonComponents/Title/Title";
import BreadCrump from "../../commonComponents/Breadcrump/Breadcrump";
import Button from "../../commonComponents/Button/Button";
import EntriesCounter from "../../commonComponents/EntriesCounter/EntriesCounter";
import Input from "../../commonComponents/Input/Input";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import Table from "../../commonComponents/Table/Table";
import useTranslate from "../../utils/Hooks/useTranslate";
import GenericSearchIcon from "../../assets/icons/Search1.svg";
import { useNavigate, useParams } from "react-router-dom";
import Actions from "../../commonComponents/Actions/Actions";
import DateViewer from "../../commonComponents/DateViewer/DateViewer";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteNewsComments,
  exportNewsComments,
  getNewsComments,
} from "../../redux/actionCreator";
import {
  news_comments,
  news_comments_page_status,
  news_comments_pages,
  news_loader,
  update_news_loading,
} from "../../redux/commonReducer";
import DeleteIcon from "../../assets/icons/delete.svg";
import Modal from "../../commonComponents/Modal/Modal";
import ModalContent from "../../commonComponents/ModalContent/ModalContent";
import FooterButtons from "../../commonComponents/FooterButtons/FooterButtons";
import BackIcon from "../../assets/icons/Back.svg";
import UploadIcon from "../../assets/icons/upload_export.svg";

export default function NewsComments() {
  const { t } = useTranslate();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const lang = localStorage.getItem("lang");

  const newsCommentData = useSelector(news_comments);
  const loading = useSelector(news_loader);
  const pageCount = useSelector(news_comments_pages);
  const pageStatus = useSelector(news_comments_page_status);
  const updateLoading = useSelector(update_news_loading);

  const initialFilter = {
    page: 0,
    perPage: 10,
    sortColumn: "updated_at",
    sortValue: -1,
    date_from: "",
    date_to: "",
    keyword: "",
  };

  const [data, setData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteID, setDeleteID] = useState("");

  const [filterData, setFilterData, F_navigate] = useFilter({
    initialFilter,
    dataFetch: (filter) => {
      getSearchDataHandler(filter);
    },
    dependency: [],
  });

  function filterHandler(key, value) {
    let filter;
    switch (key) {
      case "searchBox":
        filter = { ...filterData, ...value, page: 0 };
        break;
      case "clearSearch":
        filter = initialFilter;
        break;
      case "keyword":
        filter = { ...filterData, keyword: value, page: 0 };
        break;
      case "sort":
        filter = {
          ...filterData,
          sortColumn: value.key,
          sortValue: value.value,
        };
        break;
      case "page":
        filter = { ...filterData, page: value };
        break;
      case "entries":
        filter = { ...filterData, page: 0, perPage: parseInt(value) };
        break;
      default:
    }

    if (!filter) return;
    setFilterData(filter);
    getSearchDataHandler(filter);
  }

  useEffect(() => {
    setData([]);
    if (newsCommentData) {
      newsCommentData?.forEach((item, i) => {
        setData((s) => [
          ...s,
          {
            message: item?.comments?.message,
            commented_by: item?.comments?.commented_by?.[0]?.name,
            created_at: (
              <DateViewer
                dateTime={item?.comments?.created_at}
                dir="ltr"
                dateOnly
              />
            ),
            action: (
              <Actions
                buttons={{
                  delete: DeleteIcon,
                }}
                onDelete={() => showDeletePopup(item?.comments?._id)}
              />
            ),
          },
        ]);
      });
    }
  }, [newsCommentData, lang]);

  const showDeletePopup = (del_id) => {
    setShowDeleteModal(true);
    setDeleteID(del_id);
  };

  const getSearchDataHandler = (filter) => {
    let value = {
      id: id,
      page: filter.page,
      perPage: filter.perPage,
      sort_order: filter.sortValue,
      sort_key: filter.sortColumn,
      keyword: filter?.keyword?.trim() ?? "",
    };
    dispatch(getNewsComments(value));
  };

  const deleteHandler = () => {
    const value = {
      news_id: id,
      comment_id: deleteID,
    };
    dispatch(
      deleteNewsComments(value, () => {
        setShowDeleteModal(false);
        if (
          newsCommentData?.length == 1 &&
          pageCount > 1 &&
          filterData?.page != 0
        ) {
          setFilterData({
            ...filterData,
            page: filterData?.page - 1,
          });
          let value = {
            id: id,
            page: filterData?.page - 1,
            perPage: filterData.perPage,
            sort_order: filterData.sortValue,
            sort_key: filterData.sortColumn,
            keyword: filterData?.keyword?.trim() ?? "",
          };
          dispatch(getNewsComments(value));
        } else {
          getSearchDataHandler(filterData);
        }
      })
    );
  };

  const handleExport = () => {
    dispatch(exportNewsComments(id));
  };

  return (
    <div id="news_listing">
      <section className="mb-4">
        <PageHeader>
          <Title content="View News Comments" />
          <BreadCrump
            content={[
              {
                name: "View Comments",
              },
              {
                name: "News",
                path: "/news/news-list",
              },
              {
                name: "Home",
                path: "/",
              },
            ]}
          />
        </PageHeader>
      </section>

      <section className="mb-4">
        <div id="SearchBox">
          <div className={"inputs mt-4"}>
            {loading ? (
              <></>
            ) : (
              <p dir="ltr">{`${newsCommentData?.[0]?.title}: Title`}</p>
            )}
            <Button
              className="css-btn-esc4 export_btn"
              iconWidth={12}
              content={"Export"}
              icon={UploadIcon}
              reverse
              onClick={() => handleExport()}
            />
          </div>
        </div>
      </section>
      <section className="d-flex justify-content-between mb-4">
        <EntriesCounter
          value={filterData.perPage}
          onChange={(e) => {
            filterHandler("entries", e);
          }}
        />
        <Input
          value={filterData.keyword}
          type="text"
          className="css-inp-esc2 search"
          icon={GenericSearchIcon}
          placeholder={t("Generic Search")}
          reverse
          width="300px"
          field="keyword"
          showCloseBtn
          onChange={(e) => !e && filterHandler("keyword", e)}
          onSubmit={(e) => {
            filterHandler("keyword", e);
          }}
          onIconClick={(e) => {
            filterHandler("keyword", e);
          }}
        />
      </section>
      {loading ? (
        <LoadingSpinner height={"200px"} />
      ) : (
        <section className="table">
          <Table
            created_at
            className={"css-tbl-esc2"}
            titles={[
              {
                title: "Commented On",
                key: "created_at",
                sort: true,
                width: "120px",
              },
              {
                title: "Commented By",
                key: "commented_by",
                sort: true,
                width: "200px",
              },
              {
                title: "Comment",
                key: "message",
                sort: true,
                width: "150px",
              },
              { title: "Action", key: "action", width: "100px" },
            ]}
            content={data}
            onSort={(e) => filterHandler("sort", e)}
            page={filterData.page}
            pagesCount={pageCount}
            pageStatus={pageStatus}
            onPageChange={(e) => {
              filterHandler("page", e.selected);
            }}
            sortValue={{
              key: filterData.sortColumn,
              value: filterData.sortValue,
            }}
          />
          <FooterButtons>
            <Button
              right
              className="css-btn-esc3"
              content="Back"
              icon={BackIcon}
              reverse
              iconWidth="15px"
              onClick={() => navigate(-1)}
            />
          </FooterButtons>
        </section>
      )}
      <Modal show={showDeleteModal} setShow={() => setShowDeleteModal()}>
        <ModalContent header={t("Delete News")}>
          <h3
            className={`${
              lang == "en" ? "langENDirectionStyle" : ""
            } right mb-3`}
          >
            {t("Are you sure to") + " " + t("delete") + " " + t("?")}
          </h3>
          <Button
            className="css-btn-esc3"
            onClick={deleteHandler}
            content={t("Yes")}
            disabled={updateLoading}
            loader={updateLoading}
          />
          <Button
            className="css-btn-esc3"
            onClick={() => setShowDeleteModal(false)}
            content={t("No")}
            disabled={updateLoading}
            loader={updateLoading}
          />
        </ModalContent>
      </Modal>
    </div>
  );
}
