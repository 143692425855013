import React, { useEffect, useState } from "react";
import PageHeader from "../../commonComponents/PageHeader/PageHeader";
import Title from "../../commonComponents/Title/Title";
import BreadCrump from "../../commonComponents/Breadcrump/Breadcrump";
import Button from "../../commonComponents/Button/Button";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import Table from "../../commonComponents/Table/Table";
import useTranslate from "../../utils/Hooks/useTranslate";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSurveyDetail } from "../../redux/actionCreator";
import {
  detail_survey_loading,
  survey_details,
} from "../../redux/commonReducer";
import FooterButtons from "../../commonComponents/FooterButtons/FooterButtons";
import BackIcon from "../../assets/icons/Back.svg";
import Actions from "../../commonComponents/Actions/Actions";
import EyeOpen from "../../assets/icons/Eye-Open.svg";

export default function SurveyViewQuestions() {
  const { t } = useTranslate();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const lang = localStorage.getItem("lang");

  const details = useSelector(survey_details);
  const loading = useSelector(detail_survey_loading);

  const [data, setData] = useState([]);

  useEffect(() => {
    if (id) {
      dispatch(getSurveyDetail(id));
    }
  }, [id]);

  useEffect(() => {
    setData([]);
    if (details?.questions) {
      details?.questions?.forEach((item, i) => {
        setData((s) => [
          ...s,
          {
            index: i + 1,
            question: lang === "en" ? item?.question : item?.question_ar,
            options: item?.options?.length
              ? item?.options?.map((ele) => {
                  return (
                    <p>
                      {lang === "en"
                        ? ele?.options_title
                        : ele?.options_title_ar}
                    </p>
                  );
                })
              : "",
            is_comment_required: item?.is_comment_required ? t("Yes") : t("No"),
            action: (
              <Actions
                buttons={{
                  view: EyeOpen,
                }}
                onView={() =>
                  navigate(`/survey/answer-details/${id}/${item?._id}`)
                }
              />
            ),
          },
        ]);
      });
    }
  }, [details?.questions, lang]);

  return (
    <div id="events_listing">
      <section className="mb-4">
        <PageHeader>
          <Title content="View Survey Details" />
          <BreadCrump
            content={[
              {
                name: "View Survey Details",
              },
              {
                name: "Survey",
                path: "/survey/survey-list",
              },
              {
                name: "Home",
                path: "/",
              },
            ]}
          />
        </PageHeader>
      </section>

      <section className="mb-4">
        <div id="SearchBox">
          <div className={"inputs mt-4"}>
            {loading ? (
              <></>
            ) : (
              <div>
                <p dir="ltr">
                  {`${lang == "en" ? details?.title : details?.title_ar}: ${t(
                    "Survey"
                  )}`}
                </p>
                <p dir="ltr">
                  {`${details?.answered_users?.length}: ${t(
                    "Answered User Count"
                  )}`}
                </p>
              </div>
            )}
          </div>
        </div>
      </section>

      {loading ? (
        <LoadingSpinner height={"200px"} />
      ) : (
        <section className="table">
          <Table
            created_at
            className={"css-tbl-esc2"}
            titles={[
              {
                title: "Sl. No",
                key: "index",
                width: "10px",
              },
              {
                title: "Question",
                key: "question",
                width: "70%",
              },
              {
                title: "Options",
                key: "options",
                width: "200px",
              },
              {
                title: "Comment Required",
                key: "is_comment_required",
                width: "100px",
              },
              { title: "Action", key: "action", width: "50px" },
            ]}
            content={data}
          />
          <FooterButtons>
            <Button
              right
              className="css-btn-esc3"
              content="Back"
              icon={BackIcon}
              reverse
              iconWidth="15px"
              onClick={() => navigate(`/survey/survey-list`)}
            />
          </FooterButtons>
        </section>
      )}
    </div>
  );
}
