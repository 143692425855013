import React, { useEffect, useState } from "react";
import PageHeader from "../../commonComponents/PageHeader/PageHeader";
import Title from "../../commonComponents/Title/Title";
import BreadCrump from "../../commonComponents/Breadcrump/Breadcrump";
import useForm from "../../utils/Hooks/useForm";
import Button from "../../commonComponents/Button/Button";
import FooterButtons from "../../commonComponents/FooterButtons/FooterButtons";
import BackIcon from "../../assets/icons/Back.svg";
import { useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import Input from "../../commonComponents/Input/Input";
import { editorConfigAr, editorConfigEn } from "../../utils/editorConfig";
import TitleBar from "../../commonComponents/TitleBar/TitleBar";
import {
  addCalenderEvent,
  getCalenderEventDetail,
  updateCalenderEvent,
} from "../../redux/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import RadioButtons from "../../commonComponents/RadioButtons/RadioButtons";
import {
  calender_details,
  detail_calender_loading,
  fileUploadLoader,
  update_calender_loading,
} from "../../redux/commonReducer";
import TextEditor from "../../commonComponents/TextEditor/TextEditor";
import StatusModal from "../../commonComponents/StatusModal/StatusModal";
import DatePick from "../../commonComponents/DatePick/DatePick";
import moment from "moment";
import useTranslate from "../../utils/Hooks/useTranslate";

export default function CalenderAdd() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslate();

  const loading = useSelector(detail_calender_loading);
  const details = useSelector(calender_details);
  const updateLoading = useSelector(update_calender_loading);
  const uploadLoading = useSelector(fileUploadLoader);

  const [successMessage, setSuccessMessage] = useState({
    message: "",
    title: "",
    isError: false,
  });
  const [showModal, setShowModal] = useState(false);

  const {
    errors,
    inputHandler,
    inputs,
    formPlug,
    validate,
    setErrors,
    setInputs,
    clearInputs,
  } = useForm({});

  useEffect(() => {
    if (id) {
      dispatch(getCalenderEventDetail(id));
    }
  }, [id]);

  useEffect(() => {
    if (details && id) {
      let result = {
        title: details?.title,
        title_ar: details?.title_ar,
        description: details?.description,
        description_ar: details?.description_ar,
        publish: details?.publish?.toString(),
        start: details?.start
          ? new Date(moment.utc(details?.start).format("YYYY-MM-DD"))
          : "",
        start_time: details?.start_time
          ? timeToDateConverter(details?.start_time)
          : "",
        end_time: details?.end_time
          ? timeToDateConverter(details?.end_time)
          : "",
      };
      setInputs({ ...result });
    }
  }, [details]);

  const successActionAfterApiResponse = (res) => {
    setSuccessMessage({
      title: id ? t("Update Calendar") : t("Create Calendar"),
      message: res.message,
    });
    setShowModal(true);
    setTimeout(() => {
      navigate(-1);
    }, 1000);
  };

  const errActionAfterApiResponse = (res) => {
    setSuccessMessage({
      title: t("Alert"),
      message: res.message,
      isError: true,
    });
    setShowModal(true);
    if (res?.data?.redirect) {
      setTimeout(() => {
        setShowModal(false);
        navigate(-1);
      }, 1000);
    } else {
      setTimeout(() => {
        setShowModal(false);
      }, 1000);
    }
  };

  const onDetailsEditorChange = (content, field) => {
    setInputs({
      ...inputs,
      [field]: content,
    });
  };

  const onCancelHandler = () => {
    setErrors({});
    clearInputs();
    // navigate(-1);
  };

  const getMinTime = (minFullTime) => {
    if (
      moment(minFullTime, "h:mm A").format("HH:mm") <
      moment("07:00 AM", "h:mm A").format("HH:mm")
    ) {
      return new Date(new Date(minFullTime).setHours(7, 0, 0));
    } else {
      return minFullTime;
    }
  };

  const timeToDateConverter = (timeString) => {
    const [time, period] = timeString.split(" ");
    const [hours, minutes] = time.split(":");
    const isPM = period.toLowerCase() === "pm";
    let dateWithTime = new Date();
    dateWithTime.setHours(
      (parseInt(hours) % 12) + (isPM ? 12 : 0),
      parseInt(minutes),
      0,
      0
    );
    return dateWithTime;
  };

  const submitHandler = async () => {
    let isValidated = await validate();
    if (isValidated) {
      finalSubmitHandler();
    }
  };

  const finalSubmitHandler = () => {
    let formData = new FormData();
    formData.append("title", inputs?.title ?? "");
    formData.append("title_ar", inputs?.title_ar ?? "");
    formData.append("description", inputs?.description ?? "");
    formData.append("description_ar", inputs?.description_ar ?? "");
    formData.append("publish", inputs?.publish ?? "");
    formData.append(
      "start",
      inputs?.start
        ? moment(inputs?.start).format("YYYY-MM-DD hh:mm:ss")?.split(" ")[0] +
            " 00:00:00"
        : ""
    );
    formData.append(
      "start_time",
      inputs?.start_time ? moment(inputs?.start_time).format("LT") : ""
    );
    formData.append(
      "end_time",
      inputs?.end_time ? moment(inputs?.end_time).format("LT") : ""
    );
    console.log(formData, "form");
    if (id) {
      formData.append("id", id);
      dispatch(
        updateCalenderEvent(formData, (res) => {
          if (res.status) {
            successActionAfterApiResponse(res);
          } else {
            errActionAfterApiResponse(res);
          }
        })
      );
    } else {
      dispatch(
        addCalenderEvent(formData, (res) => {
          if (res.status) {
            successActionAfterApiResponse(res);
          } else {
            errActionAfterApiResponse(res);
          }
        })
      );
    }
  };

  return (
    <div id="calender_addRequest">
      <section className="mb-4">
        <PageHeader>
          <Title content={id ? "Edit Calendar" : "Add Calendar"} />
          <BreadCrump
            content={[
              {
                name: id ? "Edit Calendar" : "Add Calendar",
              },
              {
                name: "Calendar",
                path: "/calendar/calendar-list",
              },
              {
                name: "Home",
                path: "/",
              },
            ]}
          />
        </PageHeader>
      </section>
      {loading ? (
        <LoadingSpinner height={"200px"} />
      ) : (
        <>
          <TitleBar
            title={id ? "Edit Calendar" : "Add Calendar"}
            toggleButton
            defaultToggle={"show"}
            className={"css-tbr-esc1"}
          >
            <Input
              className="css-inp-esc1"
              label="Title in English"
              placeholder="Type here"
              required={{ message: t("Please enter title in english") }}
              field={"title"}
              value={inputs}
              error={errors}
              inputSocket={formPlug}
              onChange={inputHandler}
            />
            <Input
              className="css-inp-esc1"
              label="Title in Arabic"
              placeholder="Type here"
              required={{ message: t("Please enter title in arabic") }}
              // validation={{
              //   alphabetsOnly: { lang: "ar", more: '!@#$%^&*()_+-=><.,:;"' },
              // }}
              field={"title_ar"}
              value={inputs}
              error={errors}
              inputSocket={formPlug}
              onChange={inputHandler}
            />
            <TextEditor
              config={editorConfigEn}
              label={"Description in English"}
              className={'editor-ltr mb-3'}
              field={"description"}
              onBlur={(newContent) =>
                onDetailsEditorChange(newContent, "description")
              }
              value={inputs}
              error={errors}
              inputSocket={formPlug}
              onChange={inputHandler}
              required={{ message: t("Please enter description in english") }}
              placeholder="Type Here"
            />
            <TextEditor
              config={editorConfigAr}
              label={"Description in Arabic"}
              style={{ direction: "rtl" }}
              field={"description_ar"}
              className={'mb-3'}
              onBlur={(newContent) =>
                onDetailsEditorChange(newContent, "description_ar")
              }
              value={inputs}
              error={errors}
              inputSocket={formPlug}
              onChange={inputHandler}
              required={{ message: t("Please enter description in arabic") }}
              placeholder="Type Here"
            />
            <div className="row">
              <div className="col-md-3">
                <DatePick
                  className="css-dtp-esc2 mb-3"
                  title={"Date"}
                  placeholder="DD/MM/YYYY"
                  selected={inputs?.start}
                  onChange={(e, key) => {
                    setInputs((s) => ({
                      ...s,
                      start_time: "",
                      end_time: "",
                      duration: "",
                    }));
                    inputHandler(e, key);
                  }}
                  dateFormat="dd/MM/yyyy"
                  minDate={new Date()}
                  error={errors?.start}
                  inputSocket={formPlug}
                  required={{ message: t("Please select date") }}
                  field={"start"}
                  showMonthDropdown
                  showYearDropdown
                />
              </div>
              <div className="col-md-2">
                <DatePick
                  selected={inputs?.start_time}
                  onChange={(e, key) => {
                    setInputs((s) => ({
                      ...s,
                      end_time: "",
                      duration: "",
                    }));
                    inputHandler(e, key);
                  }}
                  className="css-dtp-esc2 w-100"
                  dateFormat="h:mm aa"
                  minTime={getMinTime(
                    inputs.start > new Date()
                      ? new Date(new Date(inputs.start).setHours(0, 0, 0))
                      : new Date()
                  )}
                  maxTime={
                    inputs?.end_time
                      ? new Date(inputs?.end_time)
                      : new Date().setHours(23, 59)
                  }
                  placeholder="-- : --"
                  field={"start_time"}
                  title={"Start Time"}
                  inputSocket={formPlug}
                  required={{ message: t("Please select start time") }}
                  error={errors?.start_time}
                  timeSelectOnly
                />
              </div>
              <div className="col-md-2">
                {console.log(new Date(inputs.start_time), inputs.start_time)}
                <DatePick
                  timeSelectOnly
                  selected={inputs?.end_time}
                  onChange={inputHandler}
                  className="css-dtp-esc2 w-100"
                  dateFormat="h:mm aa"
                  // minTime={getMinTime(
                  //   inputs.startDate > new Date()
                  //     ? inputs.start_time
                  //       ? new Date(inputs.start_time)
                  //       : new Date(new Date(inputs.startDate).setHours(0, 0, 0))
                  //     : inputs.start_time
                  //     ? new Date(inputs.start_time)
                  //     : new Date()
                  // )}
                  minTime={getMinTime(
                    inputs.startDate > new Date()
                      ? inputs.start_time
                        ? new Date(
                            new Date(inputs.start_time).setMinutes(
                              new Date(inputs.start_time).getMinutes() + 15
                            )
                          )
                        : new Date(new Date(inputs.startDate).setHours(0, 0, 0))
                      : inputs.start_time
                      ? new Date(
                          new Date(inputs.start_time).setMinutes(
                            new Date(inputs.start_time).getMinutes() + 15
                          )
                        )
                      : new Date()
                  )}
                  maxTime={new Date().setHours(23, 59)}
                  placeholder="-- : --"
                  field={"end_time"}
                  title={"End Time"}
                  inputSocket={formPlug}
                  required={{ message: t("Please select end time") }}
                  error={errors?.end_time}
                />
              </div>
            </div>

            <div>
              <RadioButtons
                label={"Publish"}
                buttons={[
                  {
                    label: "Yes",
                    value: "1",
                  },
                  {
                    label: "No",
                    value: "0",
                  },
                ]}
                name="publish"
                placeholder={"Select any option"}
                required={{ message: t("Please select publish status") }}
                field={"publish"}
                error={errors.publish}
                value={{
                  publish: inputs?.publish,
                }}
                onChange={(value, key) => inputHandler(value, key)}
                inputSocket={formPlug}
              />
            </div>
          </TitleBar>

          <FooterButtons>
            <Button
              right
              className="css-btn-esc3"
              content="Back"
              icon={BackIcon}
              reverse
              iconWidth="15px"
              onClick={() => navigate(-1)}
            />
            <Button
              left
              className="css-btn-esc3"
              content="Submit"
              onClick={() => submitHandler()}
              loader={updateLoading || uploadLoading}
              disabled={updateLoading || uploadLoading}
            />
            <Button
              left
              className="css-btn-esc3"
              content="Cancel"
              loader={updateLoading || uploadLoading}
              disabled={updateLoading || uploadLoading}
              onClick={() => onCancelHandler()}
            />
          </FooterButtons>
        </>
      )}
      <StatusModal
        show={showModal}
        setShow={setShowModal}
        header={successMessage?.title}
        isError={successMessage?.isError}
      >
        {successMessage?.message}
      </StatusModal>
    </div>
  );
}
