import React, { useEffect, useState } from "react";
import "./StatusModal.scss";
import Modal from "../Modal/Modal";
import ModalContent from "../ModalContent/ModalContent";
import icon_success from "./StatusModal_success.svg";
import icon_error from "./StatusModal_error.svg";

export default function StatusModal({
  show,
  setShow,
  header,
  content,
  children,
  isError,
}) {
  const [timeOutId, setTimeOutId] = useState("");

  useEffect(() => {
    if (show) {
      setTimeOutId(
        setTimeout(() => {
          setShow(false);
        }, 5000)
      );
    }
  }, [show]);

  return (
    <Modal
      className="StatusModal"
      show={show}
      setShow={(e) => {
        clearTimeout(timeOutId);
        setShow(e);
      }}
    >
      <ModalContent header={header} width={400}>
        {isError ? (
          <img className="icon error" src={icon_error} />
        ) : (
          <img className="icon success" src={icon_success} />
        )}
        <p className="status_modal_content">
          {content || children || "No Content Available"}
        </p>
      </ModalContent>
    </Modal>
  );
}

{
  /*

<StatusModal
  show={true}
  setShow={() => { }}
  header=''
  content=''
  success
  error
/>

*/
}
