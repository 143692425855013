import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Header.scss";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Hamburger from "../../assets/icons/hamburger.svg";
import "../../index.scss";
import logoutIcon from "../../assets/icons/logout-svgrepo-com.svg";
import { LangSwitch } from "../../App";
import { Capitalize } from "../../utils/helpers";
import { toast } from "react-toastify";
import { logout } from "../../redux/actionCreator";
import { logout_loader } from "../../redux/commonReducer";
import ESCDModal from "../ESCDModals/escdModal";

export default function Header({ setNavToggle, setSelected, setExpanded }) {
  const navigate = useNavigate();

  const logoutLoading = useSelector(logout_loader);

  const [onFocus, setOnFocus] = useState(false);
  const [showModal, setShowModal] = useState(false);

  let current = localStorage.getItem("lang");
  const { currentLanguage, setCurrentLanguage } = useContext(LangSwitch);

  const dispatch = useDispatch();

  const loggedUser = localStorage.getItem("user_logged");

  const designation = Capitalize(
    JSON.parse(localStorage.getItem("user"))?.designation?.designation_name
  );

  useEffect(() => {
    window.addEventListener("focus", () => setOnFocus(true));
    window.addEventListener("blur", () => setOnFocus(false));
    // Calls onFocus when the window first loads
    // onFocus();
    setOnFocus(true);
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener("focus", () => setOnFocus(true));
      window.removeEventListener("blur", () => setOnFocus(false));
    };
  }, []);

  // const [lang, setLang] = useState("En");
  useEffect(() => {
    let lg = localStorage.getItem("lang");
    if (!lg) {
      localStorage.setItem("lang", "ar");
      setCurrentLanguage("ar");
    } else {
      setCurrentLanguage(lg);
    }
  }, []);

  const logoutHandler = () => {
    dispatch(
      logout(() => {
        localStorage.clear();
        navigate("/login");
      })
    );
  };

  const langHandler = (lang) => {
    localStorage.setItem("lang", current === "ar" ? "en" : "ar");
    setCurrentLanguage(lang);
  };

  return (
    <>
      <div id="Header-shell">
        <div className="right-items">
          <i className="hamburger" onClick={() => setNavToggle((s) => !s)}>
            <img src={Hamburger} alt="" />
          </i>
          <div className="options">
            {current == "en" ? (
              <span onClick={() => langHandler("ar")}>
                <em>ع</em>
              </span>
            ) : (
              <span onClick={() => langHandler("en")}>En</span>
            )}
          </div>
        </div>
        <div className="user">
          <div className="options"></div>

          <div>
            <p className="name" style={{ marginBottom: 0 }}>
              {JSON.parse(localStorage.getItem("user"))?.displayName
                ? Capitalize(
                  JSON.parse(localStorage.getItem("user"))?.displayName
                )
                : ""}
            </p>
            <p style={{ margin: 0 }} className="designation">
              {Capitalize(
                JSON.parse(localStorage.getItem("user"))?.designation
                  ?.designation_name
              )}
            </p>
            <p style={{ margin: 0 }} className="designation">
              {Capitalize(
                JSON.parse(localStorage.getItem("user"))?.section?.[0]
                  ?.section_name
              )}
            </p>
            <p style={{ margin: 0 }} className="department">
              {Capitalize(
                JSON.parse(localStorage.getItem("user"))?.department
                  ?.department_name
              )}
            </p>
          </div>
          <i className="logout" onClick={() => setShowModal(true)}>
            <img src={logoutIcon} alt="" />
          </i>
        </div>
      </div>
      <ESCDModal
        show={showModal}
        setShow={setShowModal}
        type={"logout"}
        onLogout={logoutHandler}
        submitLoading={logoutLoading}
      />
    </>
  );
}
