import React from "react";
import "./TitleValue.scss";
import useTranslate from "../../utils/Hooks/useTranslate";

export default function TitleValue({
  title,
  value,
  dir,
  className,
  isHyperlink,
  children,
  error,
  valueIcon,
  url,
  iconOnly,
  reverse,
  iconWidth,
  span,
  onIconClick,
  titleEndElement,
}) {
  const { t, lang } = useTranslate();

  const Value = () => {
    return (
      <p className={"value" + (reverse ? " reverse" : "")} dir={dir}>
        {valueIcon && (value || iconOnly) && (
          <img
            src={valueIcon}
            style={{ width: iconWidth }}
            className="icon"
            onClick={() => onIconClick && onIconClick()}
          />
        )}
        {value || iconOnly || (children ? "" : "--")}
      </p>
    );
  };

  return (
    <span id="TitleValue" className={className || ""} lang={lang}>
      {titleEndElement ? (
        <div className="d-flex justify-content-between">
          <p className="key">{t(title) || "title"}</p>
          {titleEndElement}
        </div>
      ) : (
        <p className="key">{t(title) || "title"}</p>
      )}
      {url && (value || iconOnly) ? (
        <a
          href={url}
          target="_blank"
          className={isHyperlink ? "hyperlink" : ""}
          rel="noreferrer"
        >
          {" "}
          <Value />{" "}
        </a>
      ) : (
        <Value />
      )}
      {children && <span className="inputs">{children}</span>}
      {error && (
        <small id="error" className="error">
          {error}
        </small>
      )}
    </span>
  );
}
