import React, { useEffect, useState } from "react";
import "./Breadcrump.scss";
import { useNavigate } from "react-router-dom";
import useTranslate from "../../utils/Hooks/useTranslate";

export default function Breadcrump({
  content = [{ name: "", path: "", onClick: "" }],
}) {
  const navigate = useNavigate();
  const { t } = useTranslate();

  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(content);
    return () => {
      setItems([]);
    };
  }, [content]);

  function clickHandler(path, join, onClick) {
    onClick && onClick();
    let pathname = window.location.href;
    let arr = pathname.split("/");
    let res = arr.slice(0, path).join("/");
    if (join) res = res + "/" + join.join("/");
    res = res.split("#")[1];
    if (!path) return;
    Number.isInteger(path) ? navigate(res) : navigate(path);
  }

  return (
    <span id="Breadcrump">
      {items.map((item, i) => (
        <p
          key={i}
          onClick={() => clickHandler(item.path, item.join, item?.onClick)}
        >
          {t(item.name)}
        </p>
      ))}
    </span>
  );
}
Breadcrump.displayName = "Breadcrump";
