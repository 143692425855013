import React, { useEffect, useState } from "react";
import "./Title.scss";
import useTranslate from "../../utils/Hooks/useTranslate";

export default function Title({ content = "Title", className }) {
  const [text, setText] = useState("");
  const { t } = useTranslate();

  useEffect(() => {
    setText(content);
    return () => {
      setText("");
    };
  }, [content]);

  return (
    <h1 id="Title" className={className || ""}>
      {t(text)}
    </h1>
  );
}

Title.displayName = "Title";
