import React, { useEffect, useState } from "react";
import "./Layout.scss";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import Header from "../Header/Header";
import NavBarHeader from "../NavBarHeader/NavBarHeader";
import NavItemsListing from "../NavItemsListing/NavItemsListing";

export default function Layout({ children }) {
  const [navToggle, setNavToggle] = useState(true);
  const [selected, setSelected] = useState({ item: 1, subItem: 0 });
  const [expanded, setExpanded] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener("load", (e) =>
      e.currentTarget.innerWidth <= 1200
        ? setNavToggle(false)
        : setNavToggle(true)
    );
    window.addEventListener("resize", (e) =>
      e.target.innerWidth <= 1200 ? setNavToggle(false) : setNavToggle(true)
    );
  });

  return (
    <div id="Layout" dir="rtl">
      <main className={"main" + (navToggle ? " show" : " hide")}>
        <Header
          setNavToggle={setNavToggle}
          setSelected={setSelected}
          setExpanded={setExpanded}
        />
        {false ? (
          <LoadingSpinner height="600px" />
        ) : (
          <div className="outlet" dir="rtl">
            <Outlet />
            {/* {children} */}
          </div>
        )}
      </main>
      <aside
        className={"navigation" + (navToggle ? " show" : " hide")}
        dir="rtl"
      >
        <section className="nav__header">
          <NavBarHeader />
        </section>
        <section className="nav__body">
          <NavItemsListing
            selected={selected}
            setSelected={setSelected}
            expanded={expanded}
            setExpanded={setExpanded}
          />
        </section>
      </aside>
    </div>
  );
}
