import { createSlice } from '@reduxjs/toolkit';

export const commonSlice = createSlice({
    name : 'commonReducer',
    initialState : {
        value: true,
        department_details : [],
        log_per_page: 10,
        fileUploadLoader : false
        
    },
    reducers : {
        updateRedux: (state , data) => {
            state[data.payload.key ] = data.payload.value
        },
    },
})
export const { updateRedux } = commonSlice.actions;

export const value = (state ) => state.commonReducer.value;
export const logout_loader = (state ) => state.commonReducer.logout_loader;
export const login_loading = (state ) => state.commonReducer.login_loading;

export const dynamic_text_list = (state ) => state.commonReducer.dynamic_text_list;
export const words = (state ) => state.commonReducer.words;
export const arabic_words = (state ) => state.commonReducer.arabic_words;

export const fileUploadLoader = (state ) => state.commonReducer.fileUploadLoader;
export const section_details = (state ) => state.commonReducer.section_details;
export const department_List = (state ) => state.commonReducer.department_List;
export const designations_list = (state ) => state.commonReducer.designations_list;
export const created_by_users = (state ) => state.commonReducer.created_by_users;

export const users_list_loading = (state ) => state.commonReducer.users_list_loading;
export const all_users_list = (state ) => state.commonReducer.all_users_list;
export const all_users_list_pages = (state ) => state.commonReducer.all_users_list_pages;
export const all_users_list_page_status = (state ) => state.commonReducer.all_users_list_page_status;
export const all_users_list_wo_pagination = (state ) => state.commonReducer.all_users_list_wo_pagination;

export const permission_list_loading = (state ) => state.commonReducer.permission_list_loading;
export const permission_list = (state ) => state.commonReducer.permission_list;
export const permission_list_pages = (state ) => state.commonReducer.permission_list_pages;
export const permission_list_page_status = (state ) => state.commonReducer.permission_list_page_status;
export const modules = (state ) => state.commonReducer.modules;
export const update_permission_loading = (state ) => state.commonReducer.update_permission_loading;
export const permission_details = (state ) => state.commonReducer.permission_details;
export const detail_permission_loading = (state ) => state.commonReducer.detail_permission_loading;
export const wo_permission_full_user_list = (state ) => state.commonReducer.wo_permission_full_user_list;
export const wo_permission_user_list = (state ) => state.commonReducer.wo_permission_user_list;
export const wo_permission_user_pages = (state ) => state.commonReducer.wo_permission_user_pages;
export const wo_permission_user_page_status = (state ) => state.commonReducer.wo_permission_user_page_status;

export const news_data_list = (state ) => state.commonReducer.news_data_list;
export const news_loader = (state ) => state.commonReducer.news_loader;
export const news_data_pages = (state ) => state.commonReducer.news_data_pages;
export const news_data_page_status = (state ) => state.commonReducer.news_data_page_status;
export const update_news_loading = (state ) => state.commonReducer.update_news_loading;
export const detail_news_loading = (state ) => state.commonReducer.detail_news_loading;
export const news_details = (state ) => state.commonReducer.news_details;
export const news_comments = (state ) => state.commonReducer.news_comments;
export const news_comments_page_status = (state ) => state.commonReducer.news_comments_page_status;
export const news_comments_pages = (state ) => state.commonReducer.news_comments_pages;

export const translation_loader = (state ) => state.commonReducer.translation_loader;
export const translation_data_list = (state ) => state.commonReducer.translation_data_list;
export const translation_data_pages = (state ) => state.commonReducer.translation_data_pages;
export const translation_data_page_status = (state ) => state.commonReducer.translation_data_page_status;
export const update_translation_loading = (state ) => state.commonReducer.update_translation_loading;

export const announcement_loader = (state ) => state.commonReducer.announcement_loader;
export const announcement_data_list = (state ) => state.commonReducer.announcement_data_list;
export const announcement_data_pages = (state ) => state.commonReducer.announcement_data_pages;
export const announcement_data_page_status = (state ) => state.commonReducer.announcement_data_page_status;
export const update_announcement_loading = (state ) => state.commonReducer.update_announcement_loading;
export const detail_announcement_loading = (state ) => state.commonReducer.detail_announcement_loading;
export const announcement_details = (state ) => state.commonReducer.announcement_details;

export const training_loader = (state ) => state.commonReducer.training_loader;
export const training_data_list = (state ) => state.commonReducer.training_data_list;
export const training_data_pages = (state ) => state.commonReducer.training_data_pages;
export const training_data_page_status = (state ) => state.commonReducer.training_data_page_status;
export const update_training_loading = (state ) => state.commonReducer.update_training_loading;
export const detail_training_loading = (state ) => state.commonReducer.detail_training_loading;
export const training_details = (state ) => state.commonReducer.training_details;
export const training_applicants_details = (state ) => state.commonReducer.training_applicants_details;
export const training_applicants_page_status = (state ) => state.commonReducer.training_applicants_page_status;
export const training_applicants_pages = (state ) => state.commonReducer.training_applicants_pages;

export const events_loader = (state ) => state.commonReducer.events_loader;
export const events_data_list = (state ) => state.commonReducer.events_data_list;
export const events_data_pages = (state ) => state.commonReducer.events_data_pages;
export const events_data_page_status = (state ) => state.commonReducer.events_data_page_status;
export const update_events_loading = (state ) => state.commonReducer.update_events_loading;
export const detail_event_loading = (state ) => state.commonReducer.detail_event_loading;
export const event_details = (state ) => state.commonReducer.event_details;
export const events_applicants_details = (state ) => state.commonReducer.events_applicants_details;
export const events_applicants_page_status = (state ) => state.commonReducer.events_applicants_page_status;
export const events_applicants_pages = (state ) => state.commonReducer.events_applicants_pages;

export const update_gallery_loading = (state ) => state.commonReducer.update_gallery_loading;
export const detail_gallery_loading = (state ) => state.commonReducer.detail_gallery_loading;
export const gallery_details = (state ) => state.commonReducer.gallery_details;
export const gallery_loader = (state ) => state.commonReducer.gallery_loader;
export const gallery_data_list = (state ) => state.commonReducer.gallery_data_list;
export const gallery_data_pages = (state ) => state.commonReducer.gallery_data_pages;
export const gallery_data_page_status = (state ) => state.commonReducer.gallery_data_page_status;

export const benefits_loader = (state ) => state.commonReducer.benefits_loader;
export const benefits_data_list = (state ) => state.commonReducer.benefits_data_list;
export const benefits_data_pages = (state ) => state.commonReducer.benefits_data_pages;
export const benefits_data_page_status = (state ) => state.commonReducer.benefits_data_page_status;
export const update_benefits_loading = (state ) => state.commonReducer.update_benefits_loading;
export const detail_benefit_loading = (state ) => state.commonReducer.detail_benefit_loading;
export const benefit_details = (state ) => state.commonReducer.benefit_details;

export const quick_link_loader = (state ) => state.commonReducer.quick_link_loader;
export const quick_link_data_list = (state ) => state.commonReducer.quick_link_data_list;
export const quick_link_data_pages = (state ) => state.commonReducer.quick_link_data_pages;
export const quick_link_data_page_status = (state ) => state.commonReducer.quick_link_data_page_status;
export const update_quick_link_loading = (state ) => state.commonReducer.update_quick_link_loading;
export const detail_quick_link_loading = (state ) => state.commonReducer.detail_quick_link_loading;
export const quick_link_details = (state ) => state.commonReducer.quick_link_details;

export const faq_loader = (state ) => state.commonReducer.faq_loader;
export const faq_data_list = (state ) => state.commonReducer.faq_data_list;
export const faq_data_pages = (state ) => state.commonReducer.faq_data_pages;
export const faq_data_page_status = (state ) => state.commonReducer.faq_data_page_status;
export const update_faq_loading = (state ) => state.commonReducer.update_faq_loading;
export const detail_faq_loading = (state ) => state.commonReducer.detail_faq_loading;
export const faq_details = (state ) => state.commonReducer.faq_details;

export const policy_loader = (state ) => state.commonReducer.policy_loader;
export const policy_data_list = (state ) => state.commonReducer.policy_data_list;
export const policy_data_pages = (state ) => state.commonReducer.policy_data_pages;
export const policy_data_page_status = (state ) => state.commonReducer.policy_data_page_status;
export const update_policy_loading = (state ) => state.commonReducer.update_policy_loading;
export const detail_policy_loading = (state ) => state.commonReducer.detail_policy_loading;
export const policy_details = (state ) => state.commonReducer.policy_details;

export const calender_loader = (state ) => state.commonReducer.calender_loader;
export const update_calender_loading = (state ) => state.commonReducer.update_calender_loading;
export const calender_data_pages = (state ) => state.commonReducer.calender_data_pages;
export const calender_data_page_status = (state ) => state.commonReducer.calender_data_page_status;
export const detail_calender_loading = (state ) => state.commonReducer.detail_calender_loading;
export const calender_details = (state ) => state.commonReducer.calender_details;
export const calender_data_list = (state ) => state.commonReducer.calender_data_list;

export const update_survey_loading = (state ) => state.commonReducer.update_survey_loading;
export const detail_survey_loading = (state ) => state.commonReducer.detail_survey_loading;
export const survey_details = (state ) => state.commonReducer.survey_details;
export const survey_loader = (state ) => state.commonReducer.survey_loader;
export const survey_data_list = (state ) => state.commonReducer.survey_data_list;
export const survey_data_pages = (state ) => state.commonReducer.survey_data_pages;
export const survey_data_page_status = (state ) => state.commonReducer.survey_data_page_status;
export const survey_answer_details = (state ) => state.commonReducer.survey_answer_details;
export const survey_answer_pages = (state ) => state.commonReducer.survey_answer_pages;

export const translation_web_data_list = (state ) => state.commonReducer.translation_web_data_list;
export const translation_web_data_pages = (state ) => state.commonReducer.translation_web_data_pages;
export const translation_web_data_page_status = (state ) => state.commonReducer.translation_web_data_page_status;

export const dashboard_loading = (state ) => state.commonReducer.dashboard_loading;
export const dashboard_data = (state ) => state.commonReducer.dashboard_data;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;



export default commonSlice.reducer;