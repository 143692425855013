import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./NavItemsListing.scss";
import { useDispatch, useSelector } from "react-redux";
import dashboard from "../../assets/icons/dashboard.svg";
import human_resource from "../../assets/icons/human_resource.svg";
import useTranslate from "../../utils/Hooks/useTranslate";
import { modulePermissionKeys, userType } from "../../utils/helpers";
import plusIcon from "../../assets/icons/plus-white.svg";
import news from "../../assets/icons/sidebar/news.svg";
import announcement from "../../assets/icons/sidebar/announcement.svg";
import training from "../../assets/icons/sidebar/training.svg";
import events from "../../assets/icons/sidebar/events.svg";
import benefits from "../../assets/icons/sidebar/benefits.svg";
import links from "../../assets/icons/sidebar/links.svg";
import faq from "../../assets/icons/sidebar/faq.svg";
import policy from "../../assets/icons/sidebar/policy.svg";
import gallery from "../../assets/icons/sidebar/gallery.svg";
import calendar from "../../assets/icons/sidebar/calendar.svg";
import survey from "../../assets/icons/sidebar/survey.svg";
import users from "../../assets/icons/sidebar/users.svg";
import permission from "../../assets/icons/sidebar/permissions.svg";
import translate from "../../assets/icons/sidebar/translation.svg";

export default function NavItemsListing({
  selected,
  setSelected,
  expanded,
  setExpanded,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const lang = localStorage.getItem("lang");
  const dispatch = useDispatch();

  const { t } = useTranslate();

  let navItems = [
    {
      id: 1,
      item: "Dashboard",
      icon: dashboard,
      path: "/",
      accesskey: "",
    },
    {
      id: 2,
      item: "News",
      icon: news,
      path: "/news/news-list",
      accesskey: "news",
      subLinks: [
        "/news/news-list",
        "/news/add",
        "/news/update",
        "/news/comments",
      ],
    },
    {
      id: 3,
      item: "Announcements",
      icon: announcement,
      path: "/announcement/announcement-list",
      accesskey: "announcements",
      subLinks: [
        "/announcement/announcement-list",
        "/announcement/add",
        "/announcement/update",
      ],
    },
    {
      id: 4,
      item: "Training",
      icon: training,
      path: "/training/training-list",
      accesskey: "training",
      subLinks: [
        "/training/training-list",
        "/training/add",
        "/training/update",
        "/training/applicants-details",
      ],
    },
    {
      id: 5,
      item: "Events",
      icon: events,
      path: "/events/events-list",
      accesskey: "events",
      subLinks: [
        "/events/events-list",
        "/events/add",
        "/events/update",
        "/events/applicants-details",
      ],
    },
    {
      id: 6,
      item: "Benefits",
      icon: benefits,
      path: "/benefits/benefits-list",
      accesskey: "benefits",
      subLinks: [
        "/benefits/benefits-list",
        "/benefits/add",
        "/benefits/update",
      ],
    },
    {
      id: 7,
      item: "Quick Links",
      icon: links,
      path: "/quick_links/quick_links-list",
      accesskey: "quicklinks",
      subLinks: [
        "/quick_links/quick_links-list",
        "/quick_links/add",
        "/quick_links/update",
      ],
    },
    {
      id: 8,
      item: "FAQ",
      icon: faq,
      path: "/faq/faq-list",
      accesskey: "faq",
      subLinks: ["/faq/faq-list", "/faq/add", "/faq/update"],
    },
    {
      id: 9,
      item: "Policy",
      icon: policy,
      path: "/policy/policy-list",
      accesskey: "policy",
      subLinks: ["/policy/policy-list", "/policy/add", "/policy/update"],
    },
    {
      id: 10,
      item: "Gallery",
      icon: gallery,
      path: "/gallery/gallery-list",
      accesskey: "gallery",
      subLinks: ["/gallery/gallery-list", "/gallery/add", "/gallery/update"],
    },
    {
      id: 11,
      item: "Calendar",
      icon: calendar,
      path: "/calendar/calendar-list",
      accesskey: "calendar",
      subLinks: [
        "/calendar/calendar-list",
        "/calendar/add",
        "/calendar/update",
      ],
    },
    {
      id: 12,
      item: "Survey",
      icon: survey,
      path: "/survey/survey-list",
      accesskey: "survey",
      subLinks: [
        "/survey/survey-list",
        "/survey/add",
        "/survey/update",
        "/survey/view-questions",
        "/survey/answer-details/",
      ],
    },
    {
      id: 13,
      item: "Manage Users",
      icon: users,
      path: "/manage-users/user-list",
      adminOnly: true,
    },
    {
      id: 14,
      item: "Manage Permission",
      icon: permission,
      path: "/manage-roles/role-list",
      adminOnly: true,
      subLinks: [
        "/manage-roles/role-list",
        "/manage-roles/add",
        "/manage-roles/update",
      ],
    },
    {
      id: 15,
      item: "Translation",
      icon: translate,
      path: "/manage-translation/translation-admin",
      adminOnly: true,
      subLinks: [
        "/manage-translation/translation-admin",
        "/manage-translation/translation-web",
      ],
      subItems: [
        {
          id: 15.1,
          item: "Admin Translation",
          path: "/manage-translation/translation-admin",
          roles: "",
          subAccesskey: "",
          subItemsSubLinks: ["/manage-translation/translation-admin"],
        },
        {
          id: 15.2,
          item: "Web Translation",
          path: "/manage-translation/translation-web",
          roles: "",
          subAccesskey: "",
          subItemsSubLinks: ["/manage-translation/translation-web"],
        },
      ],
    },
  ];

  useEffect(() => {
    const path = location.pathname;
    let activeNavItems = navItems?.filter((element) => {
      if (modulePermissionKeys()?.includes(element?.accesskey)) {
        return element;
      } else if (userType() == "super_admin") {
        return element;
      }
    });

    if (path === "/") {
      setSelected({ item: 1, subItem: 1.1 });
    } else {
      let flag = false;
      for (let i = 0; i < activeNavItems?.length; i++) {
        let activeSubItems = activeNavItems[i].subItems?.filter((ele) => {
          if (modulePermissionKeys()?.includes(ele?.accesskey)) {
            return ele;
          } else if (userType() == "super_admin") {
            return ele;
          }
        });
        for (let j = 0; j < activeSubItems?.length; j++) {
          if (path?.includes(activeSubItems[j]?.path)) {
            setSelected({
              item: activeNavItems[i].id,
              subItem: activeSubItems[j].id,
            });
            setExpanded(activeNavItems[i].id);
            flag = true;
          }
        }
        if (
          (!activeSubItems || activeSubItems.length === 0) &&
          path.includes(activeNavItems[i].path)
        ) {
          setSelected({ item: activeNavItems[i].id, subItem: 0 });
        }
      }
    }
  }, [location]);

  function itemSelectionHandler(item) {
    setSelected((s) => {
      if (s.item === item.id) {
        expanded ? setExpanded(false) : setExpanded(item.id);
        if (s.item == 9 || s.item == 7) {
          navigate(item.path);
        }
      } else {
        setExpanded(item.id);
        navigate(item.path);
      }
      return { item: item.id, subItem: selected.subItem };
    });
  }

  return (
    <div id="NavItemsListing-shell">
      <ul>
        {navItems
          ?.filter((element) => {
            if (
              modulePermissionKeys()?.includes(element?.accesskey) ||
              element?.accesskey === ""
            ) {
              return element;
            } else if (userType() == "super_admin") {
              return element;
            }
          })
          ?.map((item, i) => (
            <li className="item" key={i}>
              <span
                className={
                  "item-span" +
                  (item.subLinks?.some((e) => location.pathname?.includes(e)) ||
                  location.pathname === item.path
                    ? " selected"
                    : "")
                }
                onClick={() => itemSelectionHandler(item)}
              >
                <span className="a" onClick={() => itemSelectionHandler(item)}>
                  <i>
                    <img style={item.iconStyle} src={item.icon} alt="" />
                  </i>
                  <p>
                    <span dir="ltr">
                      <span>
                        {item.dontTranslate ? item.item : t(item.item)}
                      </span>
                    </span>
                  </p>
                </span>
                {item.subItems?.length > 0 && (
                  <img
                    className={
                      expanded === item.id ? "plus-expand" : "plus-collapse"
                    }
                    src={plusIcon}
                    alt=""
                  />
                )}
              </span>
              {item.subItems &&
                selected.item === item.id &&
                expanded === item.id && (
                  <ul>
                    {item.subItems
                      ?.filter((ele) => {
                        if (ele?.subAccesskey === "") {
                          return ele;
                        }
                      })
                      ?.map((subItem, si) => (
                        <li
                          key={si}
                          className="sub-item"
                          onClick={() =>
                            setSelected((s) => ({ ...s, subItem: subItem.id }))
                          }
                        >
                          <Link to={subItem.path}>
                            <p
                              className={
                                subItem.subItemsSubLinks?.some((e) =>
                                  location.pathname?.includes(e)
                                ) || location.pathname === subItem.path
                                  ? " selected"
                                  : ""
                              }
                            >
                              <span dir="ltr">
                                <span>{t(subItem.item)}</span>
                              </span>
                            </p>
                          </Link>
                        </li>
                      ))}
                  </ul>
                )}
            </li>
          ))}
      </ul>
    </div>
  );
}
