import React from "react";
import "./SupportedFormats.scss";
import useTranslate from "../../utils/Hooks/useTranslate";

import icon_info from "../../assets/icons/info.svg";

export default function SupportedFormats({ formats, maxSize }) {
  const { t } = useTranslate();

  return (
    <p id="SupportedFormats">
      <img src={icon_info} />
      {`${t("Supported formats")} : ${
        formats?.toString().split(",").join(", ") || ""
      }${maxSize ? ` ${t("and size upto")} ${maxSize} ${t("MB")}` : ""}`}
    </p>
  );
}
