import React, { useEffect, useState } from "react";
import PageHeader from "../../commonComponents/PageHeader/PageHeader";
import Title from "../../commonComponents/Title/Title";
import BreadCrump from "../../commonComponents/Breadcrump/Breadcrump";
import useForm from "../../utils/Hooks/useForm";
import Button from "../../commonComponents/Button/Button";
import FooterButtons from "../../commonComponents/FooterButtons/FooterButtons";
import BackIcon from "../../assets/icons/Back.svg";
import { useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import TitleBar from "../../commonComponents/TitleBar/TitleBar";
import {
  addBenefit,
  getBenefitDetail,
  updateBenefit,
} from "../../redux/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import RadioButtons from "../../commonComponents/RadioButtons/RadioButtons";
import {
  benefit_details,
  detail_benefit_loading,
  fileUploadLoader,
  update_benefits_loading,
} from "../../redux/commonReducer";
import StatusModal from "../../commonComponents/StatusModal/StatusModal";
import TextArea from "../../commonComponents/TextArea/TextArea";
import useTranslate from "../../utils/Hooks/useTranslate";

export default function BenefitsAdd() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslate();

  const loading = useSelector(detail_benefit_loading);
  const details = useSelector(benefit_details);
  const updateLoading = useSelector(update_benefits_loading);
  const uploadLoading = useSelector(fileUploadLoader);

  const [successMessage, setSuccessMessage] = useState({
    message: "",
    title: "",
    isError: false,
  });
  const [showModal, setShowModal] = useState(false);

  const {
    errors,
    inputHandler,
    inputs,
    formPlug,
    validate,
    setErrors,
    setInputs,
    clearInputs,
  } = useForm({});

  useEffect(() => {
    if (id) {
      dispatch(getBenefitDetail(id));
    }
  }, [id]);

  useEffect(() => {
    if (details && id) {
      let result = {
        description: details?.description,
        description_ar: details?.description_ar,
        publish: details?.publish?.toString(),
      };

      setInputs({ ...result });
    }
  }, [details]);

  const successActionAfterApiResponse = (res) => {
    setSuccessMessage({
      title: id ? t("Update Benefits") : t("Create Benefits"),
      message: res.message,
    });
    setShowModal(true);
    setTimeout(() => {
      navigate(-1);
    }, 1000);
  };

  const errActionAfterApiResponse = (res) => {
    setSuccessMessage({
      title: t("Alert"),
      message: res.message,
      isError: true,
    });
    setShowModal(true);
    if (res?.data?.redirect) {
      setTimeout(() => {
        setShowModal(false);
        navigate(-1);
      }, 1000);
    } else {
      setTimeout(() => {
        setShowModal(false);
      }, 1000);
    }
  };

  const onCancelHandler = () => {
    setErrors({});
    clearInputs();
    // navigate(-1);
  };

  const submitHandler = async () => {
    let isValidated = await validate();
    if (isValidated) {
      finalSubmitHandler();
    }
  };

  const finalSubmitHandler = () => {
    let formData = new FormData();
    formData.append("description", inputs?.description ?? "");
    formData.append("description_ar", inputs?.description_ar ?? "");
    formData.append("publish", inputs?.publish ?? "");
    console.log(formData, "form");
    if (id) {
      formData.append("id", id);
      dispatch(
        updateBenefit(formData, (res) => {
          if (res.status) {
            successActionAfterApiResponse(res);
          } else {
            errActionAfterApiResponse(res);
          }
        })
      );
    } else {
      dispatch(
        addBenefit(formData, (res) => {
          if (res.status) {
            successActionAfterApiResponse(res);
          } else {
            errActionAfterApiResponse(res);
          }
        })
      );
    }
  };

  return (
    <div id="benefits_addRequest">
      <section className="mb-4">
        <PageHeader>
          <Title content={id ? "Edit Benefits" : "Add Benefits"} />
          <BreadCrump
            content={[
              {
                name: id ? "Edit Benefits" : "Add Benefits",
              },
              {
                name: "Benefits",
                path: "/benefits/benefits-list",
              },
              {
                name: "Home",
                path: "/",
              },
            ]}
          />
        </PageHeader>
      </section>
      {loading ? (
        <LoadingSpinner height={"200px"} />
      ) : (
        <>
          <TitleBar
            title={id ? "Edit Benefits" : "Add Benefits"}
            toggleButton
            defaultToggle={"show"}
            className={"css-tbr-esc1"}
          >
            <TextArea
              className={"css-txt-esc1 editor-ltr"}
              label={"Benefit in English"}
              placeholder={"Enter Benefit"}
              field={"description"}
              value={inputs}
              error={errors}
              inputSocket={formPlug}
              onChange={inputHandler}
              span="start fill"
              required={{ message: t("Please enter benefit in english") }}
            />
            <TextArea
              className={"css-txt-esc1"}
              label={"Benefit in Arabic"}
              placeholder={"Enter Benefit"}
              field={"description_ar"}
              value={inputs}
              error={errors}
              inputSocket={formPlug}
              onChange={inputHandler}
              span="start fill"
              required={{ message: t("Please enter benefit in arabic") }}
            />

            <div>
              <RadioButtons
                label={"Publish"}
                buttons={[
                  {
                    label: "Yes",
                    value: "1",
                  },
                  {
                    label: "No",
                    value: "0",
                  },
                ]}
                name="publish"
                placeholder={"Select any option"}
                required={{ message: t("Please select publish status") }}
                field={"publish"}
                error={errors.publish}
                value={{
                  publish: inputs?.publish,
                }}
                onChange={(value, key) => inputHandler(value, key)}
                inputSocket={formPlug}
              />
            </div>
          </TitleBar>

          <FooterButtons>
            <Button
              right
              className="css-btn-esc3"
              content="Back"
              icon={BackIcon}
              reverse
              iconWidth="15px"
              onClick={() => navigate(-1)}
            />
            <Button
              left
              className="css-btn-esc3"
              content="Submit"
              onClick={() => submitHandler()}
              loader={updateLoading || uploadLoading}
              disabled={updateLoading || uploadLoading}
            />
            <Button
              left
              className="css-btn-esc3"
              content="Cancel"
              loader={updateLoading || uploadLoading}
              disabled={updateLoading || uploadLoading}
              onClick={() => onCancelHandler()}
            />
          </FooterButtons>
        </>
      )}
      <StatusModal
        show={showModal}
        setShow={setShowModal}
        header={successMessage?.title}
        isError={successMessage?.isError}
      >
        {successMessage?.message}
      </StatusModal>
    </div>
  );
}
