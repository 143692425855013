import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./NavBarHeader.scss";
import Option1 from "../../assets/icons/Option-1.svg";

export default function NavBarHeader() {
  const location = useLocation();

  return (
    <div id="NavBarHeader">
      <div className="logo">
        <img src={Option1} alt="" />
      </div>
    </div>
  );
}
