import React from "react";
import "./PersonalInformation.scss";
import TitleValue from "../../../commonComponents/TitleValue/TitleValue";
import DateViewer from "../../../commonComponents/DateViewer/DateViewer";

export default function PersonalInformation({ user, page }) {
  const lang = localStorage.getItem("lang");
  return (
    <div id="PersonalInformation">
      <article>
        <TitleValue title="Employee No" value={user?.employee_id} />
        <TitleValue title="First Name" value={user?.firstname} />
        <TitleValue title="Last Name" value={user?.lastname} />
        <TitleValue title="Email" value={user?.email} />
        <TitleValue title="Phone" value={user?.phone} />
        <TitleValue
          title="Alternative Phone No"
          value={user?.alternate_phone}
        />
        <TitleValue title="Grade" value={user?.grade} />
        <TitleValue
          title="Designation"
          value={user?.designation?.designation_name}
        />
        <TitleValue
          title="Department"
          value={
            lang === "en"
              ? user?.department?.department_name
              : user?.department?.department_name_ar
          }
        />
        <TitleValue
          title="Section"
          value={
            lang === "en"
              ? user?.section?.[0]?.section_name
              : user?.section?.[0]?.section_name_ar
          }
        />
        <TitleValue title="Nationality" value={user?.nationality} />
        <TitleValue
          title="Date of Joining in ESC"
          value={
            user?.joining_date && (
              <DateViewer dateTime={user?.joining_date} dateOnly dir={"ltr"} />
            )
          }
        />
        <TitleValue
          title="Secondary Designation"
          value={user?.sub_designation?.designation_name}
        />
        <TitleValue
          title="Sub Section"
          value={user?.sub_section?.[0]?.section_name}
        />
      </article>
    </div>
  );
}
