import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../commonComponents/Modal/Modal";
import useTranslate from "../../utils/Hooks/useTranslate";
import ModalContent from "../../commonComponents/ModalContent/ModalContent";
import Button from "../../commonComponents/Button/Button";

export default function ESCDModal({
  show,
  setShow,
  type,
  onLogout,
  submitLoading,
}) {
  const dispatch = useDispatch();

  const { t } = useTranslate();
  const lang = localStorage.getItem("lang");

  const flag = type === "logout" ? 1 : 0;

  return (
    <div id="Modals">
      <Modal show={show} setShow={setShow}>
        {flag === 1 ? (
          <LogoutModal
            setShow={setShow}
            onLogout={onLogout}
            submitLoading={submitLoading}
          />
        ) : null}
      </Modal>
    </div>
  );
}

const LogoutModal = ({ setShow, onLogout, submitLoading }) => {
  const { t } = useTranslate();
  const lang = localStorage.getItem("lang");

  return (
    <div className="modal-item">
      <ModalContent header={t("Logout")}>
        <h3 className={`${lang == "en" ? "langENDirectionStyle" : ""} right`}>
          {t("Are you sure to") + " " + t("logout") + " " + t("?")}
        </h3>

        <Button
          className="css-btn-esc3"
          onClick={() => onLogout()}
          disabled={submitLoading}
          content={t("Confirm")}
          loader={submitLoading}
        />
        <Button
          className="css-btn-esc3"
          onClick={() => setShow(false)}
          content={t("Cancel")}
        />
      </ModalContent>
    </div>
  );
};
