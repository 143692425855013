import React, { useEffect, useState } from "react";
import useFilter from "../../utils/Hooks/useFilter";
import PageHeader from "../../commonComponents/PageHeader/PageHeader";
import Title from "../../commonComponents/Title/Title";
import BreadCrump from "../../commonComponents/Breadcrump/Breadcrump";
import Button from "../../commonComponents/Button/Button";
import SearchBox from "../../commonComponents/SearchBox/SearchBox";
import EntriesCounter from "../../commonComponents/EntriesCounter/EntriesCounter";
import Input from "../../commonComponents/Input/Input";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import Table from "../../commonComponents/Table/Table";
import useTranslate from "../../utils/Hooks/useTranslate";
import GenericSearchIcon from "../../assets/icons/Search1.svg";
import AddRequesticon from "../../assets/icons/Add Request.svg";
import { useNavigate } from "react-router-dom";
import EditIcon from "../../assets/icons/Edit.svg";
import Actions from "../../commonComponents/Actions/Actions";
import DateViewer from "../../commonComponents/DateViewer/DateViewer";
import { dateFormatter } from "../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import {
  errorToast,
  getCreatedByUsers,
  successToast,
  getFaqs,
  deleteFaq,
  updateFaqStatus,
  exportFaqs,
} from "../../redux/actionCreator";
import {
  created_by_users,
  faq_data_list,
  faq_data_page_status,
  faq_data_pages,
  faq_loader,
  update_faq_loading,
} from "../../redux/commonReducer";
import DeleteIcon from "../../assets/icons/delete.svg";
import Modal from "../../commonComponents/Modal/Modal";
import ModalContent from "../../commonComponents/ModalContent/ModalContent";

export default function FAQList() {
  const { t } = useTranslate();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const lang = localStorage.getItem("lang");

  const faqData = useSelector(faq_data_list);
  const loading = useSelector(faq_loader);
  const pageCount = useSelector(faq_data_pages);
  const pageStatus = useSelector(faq_data_page_status);
  const updateLoading = useSelector(update_faq_loading);
  const createdByUsers = useSelector(created_by_users);

  const initialFilter = {
    page: 0,
    perPage: 10,
    sortColumn: "updated_at",
    sortValue: -1,
    date_from: "",
    date_to: "",
    keyword: "",
    created_by: "",
  };

  const [data, setData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteID, setDeleteID] = useState("");
  const [statusId, setStatusID] = useState("");
  const [statusShow, setStatusShow] = useState(false);

  const [dateFilter, setDateFilter] = useState({
    end_date_max: new Date(),
    start_date_max: new Date(),
  });

  const [filterData, setFilterData, F_navigate] = useFilter({
    initialFilter,
    dataFetch: (filter) => {
      getSearchDataHandler(filter);
    },
    dependency: [],
  });

  useEffect(() => {
    let value = {
      type: "Faq",
    };
    dispatch(getCreatedByUsers(value));
  }, []);

  function filterHandler(key, value) {
    let filter;
    switch (key) {
      case "searchBox":
        filter = { ...filterData, ...value, page: 0 };
        break;
      case "clearSearch":
        filter = initialFilter;
        break;
      case "keyword":
        filter = { ...filterData, keyword: value, page: 0 };
        break;
      case "sort":
        filter = {
          ...filterData,
          sortColumn: value.key,
          sortValue: value.value,
        };
        break;
      case "page":
        filter = { ...filterData, page: value };
        break;
      case "entries":
        filter = { ...filterData, page: 0, perPage: parseInt(value) };
        break;
      default:
    }

    if (!filter) return;
    setFilterData(filter);
    getSearchDataHandler(filter);
  }

  useEffect(() => {
    setData([]);
    if (faqData) {
      faqData?.forEach((item, i) => {
        setData((s) => [
          ...s,
          {
            question: lang == "en" ? item?.question : item?.question_ar,
            publish: (
              <>
                <span
                  className={`${
                    item.publish == 1 ? "active" : ""
                  } publishButton cursor-pointer`}
                  onClick={() => statusHandler(item._id)}
                >
                  {t("Yes")}
                </span>
                <span
                  className={`${
                    item.publish == 0 ? "active" : ""
                  } publishButton cursor-pointer`}
                  onClick={() => statusHandler(item._id)}
                >
                  {t("No")}
                </span>
              </>
            ),
            created_by: item?.created_by?.name,
            created_at: (
              <DateViewer dateTime={item?.created_at} dir="ltr" dateOnly />
            ),
            updated_at: <DateViewer dateTime={item?.updated_at} dir="ltr" />,
            action: (
              <Actions
                buttons={{
                  edit: EditIcon,
                  delete: DeleteIcon,
                }}
                onEdit={() => F_navigate(`/faq/update/${item?._id}`)}
                onDelete={() => showDeletePopup(item?._id)}
              />
            ),
          },
        ]);
      });
    }
  }, [faqData, lang]);

  const showDeletePopup = (id) => {
    setShowDeleteModal(true);
    setDeleteID(id);
  };

  const getSearchDataHandler = (filter) => {
    let formData = new FormData();
    formData.append("page", filter.page);
    formData.append("perPage", filter.perPage);
    formData.append("sort_order", filter.sortValue);
    formData.append("sort_key", filter.sortColumn);
    formData.append("start_date", dateFormatter(filter?.date_from, true));
    formData.append("end_date", dateFormatter(filter?.date_to, false));
    formData.append("created_by[]", filter?.created_by?._id ?? "");
    formData.append("keyword", filter?.keyword?.trim() ?? "");
    dispatch(getFaqs(formData));
    console.log(formData);
  };

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  function searchBoxChangeHandler(value, key, setValues, values) {
    if (key === "department") {
      setValues((s) => ({ ...s, employee: "" }));
      let formData = new FormData();
      formData.append("department", value?._id || "");
      formData.append("section", "");
      formData.append("for_filter", 1);
    }
    if (key === "date_from") {
      setDateFilter((s) => ({ ...s, end_date_min: new Date(value) }));
    }
    if (key === "date_to") {
      setDateFilter((s) => ({ ...s, start_date_max: new Date(value) }));
    }
  }

  const deleteHandler = () => {
    const value = {
      id: deleteID,
    };
    dispatch(
      deleteFaq(value, () => {
        setShowDeleteModal(false);
        if (faqData?.length == 1 && pageCount > 1 && filterData?.page != 0) {
          setFilterData({
            ...filterData,
            page: filterData?.page - 1,
          });
          let formData = new FormData();
          formData.append("page", filterData?.page - 1);
          formData.append("perPage", filterData.perPage);
          formData.append("sort_order", filterData.sortValue);
          formData.append("sort_key", filterData.sortColumn);
          formData.append(
            "start_date",
            dateFormatter(filterData?.date_from, true)
          );
          formData.append("end_date", filterData(filterData?.date_to, false));
          formData.append("created_by[]", filterData?.created_by?._id ?? "");
          formData.append("keyword", filterData?.keyword?.trim() ?? "");
          dispatch(getFaqs(formData));
        } else {
          getSearchDataHandler(filterData);
        }
      })
    );
  };

  const statusHandler = (id) => {
    setStatusShow(true);
    setStatusID(id);
  };
  const onUpdateStatusHandler = () => {
    dispatch(
      updateFaqStatus(statusId, (res) => {
        if (res?.status) {
          setStatusShow(false);
          setStatusID("");
          successToast(res.message);
          getSearchDataHandler(filterData);
        } else {
          errorToast(res.message);
        }
      })
    );
  };

  const handleExport = () => {
    let formData = new FormData();
    formData.append("page", filterData.page);
    formData.append("perPage", filterData.perPage);
    formData.append("sort_order", filterData.sortValue);
    formData.append("sort_key", filterData.sortColumn);
    formData.append("start_date", dateFormatter(filterData?.date_from, true));
    formData.append("end_date", dateFormatter(filterData?.date_to, false));
    formData.append("created_by[]", filterData?.created_by?._id ?? "");
    formData.append("keyword", filterData?.keyword?.trim() ?? "");
    dispatch(exportFaqs(formData));
  };

  return (
    <div id="faq_listing">
      <section className="mb-4">
        <PageHeader>
          <Title content="FAQ" />
          <BreadCrump
            content={[
              {
                name: "FAQ",
              },
              {
                name: "Home",
                path: "/",
              },
            ]}
          />
          <Button
            content={"Add Request"}
            className="css-btn-esc1"
            icon={AddRequesticon}
            reverse
            onClick={() => navigate(`/faq/add`)}
          />
        </PageHeader>
      </section>
      <section className="mb-4">
        <SearchBox
          elements={[
            {
              label: t("Created Date"),
              key: "date_from",
              type: "datePicker",
              className: "css-dtp-esc3",
              placeholder: t("From"),
            },
            {
              label: "",
              key: "date_to",
              type: "datePicker",
              className: "css-dtp-esc3",
              placeholder: t("To"),
            },
            {
              label: "Created By",
              key: "created_by",
              type: "select",
              className: "css-slc-esc2",
              placeholder: "Select User",
              width: 250,
            },
          ]}
          options={{
            date_from: {
              selected: filterData?.date_from,
              value: filterData?.date_from,
              filterTime: filterPassedTime,
              maxDate: dateFilter?.start_date_max
                ? dateFilter?.start_date_max
                : new Date(),
              dateFormat: "dd/MM/yyyy",
            },
            date_to: {
              selected: filterData?.date_to,
              value: filterData?.date_to,
              filterTime: filterPassedTime,
              minDate: dateFilter?.end_date_min,
              dateFormat: "dd/MM/yyyy",
              maxDate: new Date(),
            },
            created_by: {
              options: createdByUsers ?? [],
              labelSetter: (o) => o?.name,
              valueSetter: (o) => o._id,
              value: filterData?.created_by,
            },
          }}
          onSearch={(values) => {
            filterHandler("searchBox", values);
          }}
          onClear={() => {
            filterHandler("clearSearch");
          }}
          onChange={searchBoxChangeHandler}
          dependency={[filterData]}
          onExport={handleExport}
        />
      </section>
      <section className="d-flex justify-content-between mb-4">
        <EntriesCounter
          value={filterData.perPage}
          onChange={(e) => {
            filterHandler("entries", e);
          }}
        />
        <Input
          value={filterData.keyword}
          type="text"
          className="css-inp-esc2 search"
          icon={GenericSearchIcon}
          placeholder={t("Generic Search")}
          reverse
          width="300px"
          field="keyword"
          showCloseBtn
          onChange={(e) => !e && filterHandler("keyword", e)}
          onSubmit={(e) => {
            filterHandler("keyword", e);
          }}
          onIconClick={(e) => {
            filterHandler("keyword", e);
          }}
        />
      </section>
      {loading ? (
        <LoadingSpinner height={"200px"} />
      ) : (
        <section className="table">
          <Table
            created_at
            className={"css-tbl-esc2"}
            titles={[
              {
                title: "Created Date",
                key: "created_at",
                sort: true,
                width: "120px",
              },
              {
                title: "Created By",
                key: "created_by",
                sort: true,
                width: "150px",
              },
              {
                title: "Question",
                key: "question",
                sort: true,
                width: "200px",
              },
              {
                title: "Publish",
                key: "publish",
                sort: true,
                width: "10px",
              },
              {
                title: "Last Update",
                key: "updated_at",
                sort: true,
                width: "150px",
              },
              { title: "Action", key: "action", width: "100px" },
            ]}
            content={data}
            onSort={(e) => filterHandler("sort", e)}
            page={filterData.page}
            pagesCount={pageCount}
            pageStatus={pageStatus}
            onPageChange={(e) => {
              filterHandler("page", e.selected);
            }}
            sortValue={{
              key: filterData.sortColumn,
              value: filterData.sortValue,
            }}
          />
        </section>
      )}
      <Modal show={showDeleteModal} setShow={() => setShowDeleteModal()}>
        <ModalContent header={t("Delete FAQ")}>
          <h3
            className={`${
              lang == "en" ? "langENDirectionStyle" : ""
            } right mb-3`}
          >
            {t("Are you sure to") + " " + t("delete") + " " + t("?")}
          </h3>
          <Button
            className="css-btn-esc3"
            onClick={deleteHandler}
            content={t("Yes")}
            disabled={updateLoading}
            loader={updateLoading}
          />
          <Button
            className="css-btn-esc3"
            onClick={() => setShowDeleteModal(false)}
            content={t("No")}
            disabled={updateLoading}
            loader={updateLoading}
          />
        </ModalContent>
      </Modal>
      <Modal show={statusShow} setShow={() => setStatusShow(false)}>
        <ModalContent header={t("Update Status")}>
          <h3
            className={`${
              lang == "en" ? "langENDirectionStyle" : ""
            } right mb-3`}
          >
            {t("Are you sure to") + " " + t("update status") + " " + t("?")}
          </h3>
          <Button
            className="css-btn-esc3"
            onClick={onUpdateStatusHandler}
            content={t("Yes")}
            disabled={updateLoading}
            loader={updateLoading}
          />
          <Button
            className="css-btn-esc3"
            onClick={() => setStatusShow(false)}
            content={t("No")}
            disabled={updateLoading}
            loader={updateLoading}
          />
        </ModalContent>
      </Modal>
    </div>
  );
}
