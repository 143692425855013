import React from "react";

const LoadingSpinner = ({ height }) => {
  return (
    <div
      style={{
        width: "100%",
        height: height ? height : 200,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img src="/assets/icons/dualBallLoader.gif" style={{ width: "70px" }} />
    </div>
  );
};

export default LoadingSpinner;
