import axios from "axios";
import { toast } from "react-toastify";
import { API_BASE_URL } from "../constants/configuration";
import axiosInstance from "../utils/axiosInterceptor";
import { updateRedux } from "./commonReducer";

export function successToast(msg) {
  toast.success(msg, {
    position: "bottom-center",
    autoClose: 3000,
  });
}
export function errorToast(msg) {
  toast.error(msg, {
    position: "bottom-center",
    autoClose: 3000,
  });
}

export const login = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "login_loading", value: true }));
  axios
    .post(`${API_BASE_URL}login`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "login_loading", value: false }));
      if (res.data.status === true) {
        localStorage.setItem("token", res.data.accessToken);
        localStorage.setItem("user_type", res.data.role);
        localStorage.setItem("username", res.data.username);
        localStorage.setItem("user", JSON.stringify(res.data));
        localStorage.setItem("user_id", res.data._id);
        localStorage.setItem("current_user_permissions", JSON.stringify(res.data.permissions));
        localStorage.setItem("permission_keys", res.data.permission_keys ? JSON.stringify([...res.data.permission_keys]) : []);
        dispatch(updateRedux({ key: "current_user_permissions", value: res.data.permissions, }));
        dispatch(updateRedux({ key: "access", value: res.data.access }));
        dispatch(updateRedux({ key: "permission_keys", value: res.data.permission_keys ? JSON.stringify([...res.data.permission_keys]) : [] }));
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error("Please check your username or password", {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "login_loading", value: false }));
      console.log(err);
    });
};

export const logout = (callback) => (dispatch) => {
  let data = {
    accessToken: localStorage.getItem("token"),
  };
  dispatch(updateRedux({ key: "logout_loader", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}logout`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "logout_loader", value: false }));
      if (res.data.status) {
        if (callback) {
          callback();
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "logout_loader", value: false }));
      console.log(err);
    });
};

export const updateDynamicTexts = (data, callback) => (dispatch) => {
  axiosInstance
    .post(`${API_BASE_URL}hr/updateText`, data)
    .then((res) => {
      if (res.data.status) {
        callback &&
          callback({ status: true, message: res.data.message, data: res.data });
      } else {
        callback && callback({ status: false, message: res.data.message });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const insertDynamicText = (data, callback) => (dispatch) => {
  axiosInstance
    .post(`${API_BASE_URL}hr/insertText`, data)
    .then((res) => {
      if (res.data.status) {
        callback && callback({ status: true, message: res.data.message });
      } else {
        callback && callback({ status: false, message: res.data.message });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const importDynamicExcel = (formdata, callback) => (dispatch) => {
  axiosInstance
    .post(`${API_BASE_URL}hr/importDynamicExcel`, formdata)
    .then((res) => {
      if (res.data.status) {
        successToast(res.data.message);
        callback && callback(res.data);
      } else {
        errorToast(res.data.message);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getDepartments = () => (dispatch) => {
  axiosInstance
    .get(`${API_BASE_URL}getDepartments`)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateRedux({ key: "department_List", value: res.data.department_List, }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getSection = (id) => (dispatch) => {
  dispatch(updateRedux({ key: "section_details", value: [] }));
  axiosInstance
    .get(`${API_BASE_URL}getSection/${id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateRedux({ key: "section_details", value: res.data.section_details }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getAllUsers = (formData) => (dispatch) => {
  axiosInstance
    .post(`${API_BASE_URL}getAllUsers`, formData)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateRedux({ key: "all_users_list_wo_pagination", value: res.data.user_list }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getAllUsersWithPagination = (formData) => (dispatch) => {
  dispatch(updateRedux({ key: "users_list_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}getAllUsersWithPagination`, formData)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateRedux({ key: "users_list_loading", value: false }));
        dispatch(updateRedux({ key: "all_users_list", value: res.data.users_list }));
        dispatch(updateRedux({ key: "all_users_list_pages", value: res.data.pages }));
        dispatch(updateRedux({ key: "all_users_list_page_status", value: res.data.page_status }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "users_list_loading", value: false }));
      console.log(err);
    });
};

export const getDesignations = (formData) => (dispatch) => {
  axiosInstance
    .post(`${API_BASE_URL}getDesignations`, formData)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateRedux({ key: "designations_list", value: res.data.data })
        );
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getPermissionsList = (formData) => (dispatch) => {
  dispatch(updateRedux({ key: "permission_list_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}getPermissionsList`, formData)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateRedux({ key: "permission_list_loading", value: false }));
        dispatch(updateRedux({ key: "permission_list", value: res.data.roles }));
        dispatch(updateRedux({ key: "permission_list_pages", value: res.data.total_pages }));
        dispatch(updateRedux({ key: "permission_list_page_status", value: res.data.page_status }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "permission_list_loading", value: false }));
      console.log(err);
    });
};

export const getModules = () => (dispatch) => {
  axiosInstance
    .get(`${API_BASE_URL}getModules`)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateRedux({ key: "modules", value: res.data.modules }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const addPermissions = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_permission_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}addPermissions`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "update_permission_loading", value: false }));
      if (callback) {
        callback(res.data);
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_permission_loading", value: false }));
      console.log(err);
    });
};

export const deletePermission = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_permission_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}deletePermission`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "update_permission_loading", value: false }));
      if (res.data.status) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback();
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_permission_loading", value: false }));
      console.log(err);
    });
};

export const getPermissionDetails = (id) => (dispatch) => {
  dispatch(updateRedux({ key: "detail_permission_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getPermissionDetails/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_permission_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "permission_details", value: res.data.modules }));
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_permission_loading", value: false }));
      console.log(err);
    });
};

export const updatePermissions = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_permission_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}updatePermissions`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "update_permission_loading", value: false }));
      if (callback) {
        callback(res.data);
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_permission_loading", value: false }));
      console.log(err);
    });
};

export const getUsersWithoutPermissions = (formData) => (dispatch) => {
  axiosInstance
    .post(`${API_BASE_URL}getUsersWithoutPermissions`, formData)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateRedux({ key: "wo_permission_full_user_list", value: res.data.roles }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getUsersWithNoPermissions = (formData, callback) => (dispatch) => {
  axiosInstance
    .post(`${API_BASE_URL}getUsersWithNoPermissions`, formData)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateRedux({ key: "wo_permission_user_list", value: res.data.roles }));
        dispatch(updateRedux({ key: "wo_permission_user_pages", value: res.data.total_pages }));
        dispatch(updateRedux({ key: "wo_permission_user_page_status", value: res.data.page_status }));
        callback && callback()
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};


export const uploadFile = (formData, callback, progress) => (dispatch) => {
  dispatch(updateRedux({ key: "fileUploadLoader", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}upload-file`, formData, {
      onUploadProgress: (e) =>
        progress && progress(Math.round(e.progress * 100)),
    })
    .then((res) => {
      dispatch(updateRedux({ key: "fileUploadLoader", value: false }));
      if (res.data.status == true) {
        if (callback) {
          callback(res.data);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const addNews = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_news_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}addNews`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_news_loading", value: false }));
      callback && callback(res.data);
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_news_loading", value: false }));
      console.log(err);
    });
};

export const getNews = (formData) => (dispatch) => {
  dispatch(updateRedux({ key: "news_loader", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}getNews`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "news_loader", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "news_data_list", value: res.data.news_list }));
        dispatch(updateRedux({ key: "news_data_pages", value: res.data.pages }));
        dispatch(updateRedux({ key: "news_data_page_status", value: res.data.page_status }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "news_loader", value: false }));
      console.log(err);
    });
};

export const deleteNews = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_news_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}deleteNews`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "update_news_loading", value: false }));
      if (res.data.status) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback();
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_news_loading", value: false }));
      console.log(err);
    });
};

export const newsDetails = (id) => (dispatch) => {
  dispatch(updateRedux({ key: "detail_news_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}newsDetails/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_news_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "news_details", value: res.data.news_details })
        );
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_news_loading", value: false }));
      console.log(err);
    });
};

export const updateNews = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_news_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}updateNews`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_news_loading", value: false }));
      callback && callback(res.data);
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_news_loading", value: false }));
      console.log(err);
    });
};

export const updateNewsStatus = (id, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_news_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}updateNewsStatus/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "update_news_loading", value: false }));
      if (callback) {
        callback(res.data);
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_news_loading", value: false }));
      console.log(err);
    });
};

export const getNewsComments = (formData) => (dispatch) => {
  dispatch(updateRedux({ key: "news_loader", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}getNewsComments`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "news_loader", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "news_data_list", value: res.data.news_list }));
        dispatch(updateRedux({ key: "news_comments", value: res.data.comments }));
        dispatch(updateRedux({ key: "news_comments_page_status", value: res.data.page_status }));
        dispatch(updateRedux({ key: "news_comments_pages", value: res.data.pages }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "news_loader", value: false }));
      console.log(err);
    });
};

export const deleteNewsComments = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_news_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}deleteNewsComments`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "update_news_loading", value: false }));
      if (res.data.status) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback();
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_news_loading", value: false }));
      console.log(err);
    });
};

export const getCreatedByUsers = (data) => (dispatch) => {
  axiosInstance
    .post(`${API_BASE_URL}getCreatedByUsers`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "news_loader", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "created_by_users", value: res.data.users }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getTexts = (formData) => (dispatch) => {
  dispatch(updateRedux({ key: "translation_loader", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}getTexts`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "translation_loader", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "translation_data_list", value: res.data.translate_details }));
        dispatch(updateRedux({ key: "translation_data_pages", value: res.data.pages }));
        dispatch(updateRedux({ key: "translation_data_page_status", value: res.data.page_status }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "translation_loader", value: false }));
      console.log(err);
    });
};

export const insertText = (data, callback) => (dispatch) => {
  axiosInstance
    .post(`${API_BASE_URL}insertText`, data)
    .then((res) => {
      console.log(res.data, "res.data")
      callback && callback(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const updateText = (data, callback) => (dispatch) => {
  axiosInstance
    .post(`${API_BASE_URL}updateText`, data)
    .then((res) => {
      callback && callback(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const deleteText = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_translation_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}deleteText`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "update_translation_loading", value: false }));
      if (callback) {
        callback(res?.data);
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_translation_loading", value: false }));
      console.log(err);
    });
};

export const getDynamicTexts = (callback) => (dispatch) => {
  axiosInstance
    .get(`${API_BASE_URL}getAllTexts`)
    .then((res) => {
      if (res.data.status) {
        dispatch(
          updateRedux({
            key: "dynamic_text_list",
            value: res.data.translate_details,
          })
        );
        dispatch(updateRedux({ key: "words", value: res.data.translate_details.reduce((acc, curr) => { return { ...acc, [curr.title.toLowerCase() + '_ar']: curr.arabic_text, [curr.title.toLowerCase() + '_en']: curr.english_text } }, {}) }))
        dispatch(updateRedux({ key: "arabic_words", value: res.data.translate_details.reduce((acc, curr) => { return { ...acc, [curr.arabic_text.toLowerCase()]: curr.title.toLowerCase() } }, {}) }))
        callback && callback(res.data.translate_details);
      } else {
        errorToast(res.data.message);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};


export const exportNews = (formData) => (dispatch) => {
  axios.post(`${API_BASE_URL}exportNews`, formData, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${localStorage.getItem("token")}`
    },
  }).then((res) => {
    if (res.status == 200) {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        'export.xlsx',
      );
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const exportNewsComments = (id) => (dispatch) => {
  axios.get(`${API_BASE_URL}exportNewsComments/${id}`, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${localStorage.getItem("token")}`
    },
  }).then((res) => {
    if (res.status == 200) {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        'export.xlsx',
      );
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getAnnouncement = (formData) => (dispatch) => {
  dispatch(updateRedux({ key: "announcement_loader", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}getAnnouncement`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "announcement_loader", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "announcement_data_list", value: res.data.announcement_list	 }));
        dispatch(updateRedux({ key: "announcement_data_pages", value: res.data.pages }));
        dispatch(updateRedux({ key: "announcement_data_page_status", value: res.data.page_status }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "announcement_loader", value: false }));
      console.log(err);
    });
};

export const deleteAnnouncement = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_announcement_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}deleteAnnouncement`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "update_announcement_loading", value: false }));
      if (res.data.status) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback();
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_announcement_loading", value: false }));
      console.log(err);
    });
};

export const getAnnouncementDetail = (id) => (dispatch) => {
  dispatch(updateRedux({ key: "detail_announcement_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getAnnouncementDetail/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_announcement_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "announcement_details", value: res.data.announcement_details })
        );
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_announcement_loading", value: false }));
      console.log(err);
    });
};

export const addAnnouncement = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_announcement_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}addAnnouncement`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_announcement_loading", value: false }));
      callback && callback(res.data);
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_announcement_loading", value: false }));
      console.log(err);
    });
};

export const updateAnnouncement = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_announcement_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}updateAnnouncement`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_announcement_loading", value: false }));
      callback && callback(res.data);
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_announcement_loading", value: false }));
      console.log(err);
    });
};

export const updateAnnouncementStatus = (id, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_announcement_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}updateAnnouncementStatus/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "update_announcement_loading", value: false }));
      if (callback) {
        callback(res.data);
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_announcement_loading", value: false }));
      console.log(err);
    });
};

export const exportAnnouncements = (formData) => (dispatch) => {
  axios.post(`${API_BASE_URL}exportAnnouncements`, formData, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${localStorage.getItem("token")}`
    },
  }).then((res) => {
    if (res.status == 200) {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        'export.xlsx',
      );
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getTrainings = (formData) => (dispatch) => {
  dispatch(updateRedux({ key: "training_loader", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}getTrainings`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "training_loader", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "training_data_list", value: res.data.training_list }));
        dispatch(updateRedux({ key: "training_data_pages", value: res.data.pages }));
        dispatch(updateRedux({ key: "training_data_page_status", value: res.data.page_status }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "training_loader", value: false }));
      console.log(err);
    });
};

export const addTraining = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_training_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}addTraining`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_training_loading", value: false }));
      callback && callback(res.data);
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_training_loading", value: false }));
      console.log(err);
    });
};

export const updateTraining = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_training_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}updateTraining`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_training_loading", value: false }));
      callback && callback(res.data);
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_training_loading", value: false }));
      console.log(err);
    });
};

export const updateTrainingStatus = (id, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_training_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}updateTrainingStatus/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "update_training_loading", value: false }));
      if (callback) {
        callback(res.data);
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_training_loading", value: false }));
      console.log(err);
    });
};

export const exportTrainings = (formData) => (dispatch) => {
  axios.post(`${API_BASE_URL}exportTrainings`, formData, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${localStorage.getItem("token")}`
    },
  }).then((res) => {
    if (res.status == 200) {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        'export.xlsx',
      );
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const deleteTraining = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_training_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}deleteTraining`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "update_training_loading", value: false }));
      if (res.data.status) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback();
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_training_loading", value: false }));
      console.log(err);
    });
};

export const getTrainingDetails = (id) => (dispatch) => {
  dispatch(updateRedux({ key: "detail_training_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}trainingDetails/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_training_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "training_details", value: res.data.training_details })
        );
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_training_loading", value: false }));
      console.log(err);
    });
};

export const getTrainingApplicants = (formData) => (dispatch) => {
  dispatch(updateRedux({ key: "training_loader", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}getTrainingApplicants`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "training_loader", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "training_applicants_details", value: res.data.training_details }));
        dispatch(updateRedux({ key: "training_applicants_page_status", value: res.data.page_status }));
        dispatch(updateRedux({ key: "training_applicants_pages", value: res.data.pages }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "training_loader", value: false }));
      console.log(err);
    });
};

export const exportTrainingDetails = (id) => (dispatch) => {
  axios.get(`${API_BASE_URL}exportTrainingDetails/${id}`, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${localStorage.getItem("token")}`
    },
  }).then((res) => {
    if (res.status == 200) {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        'export.xlsx',
      );
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getEvents = (formData) => (dispatch) => {
  dispatch(updateRedux({ key: "events_loader", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}getEvents`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "events_loader", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "events_data_list", value: res.data.event_list	 }));
        dispatch(updateRedux({ key: "events_data_pages", value: res.data.pages }));
        dispatch(updateRedux({ key: "events_data_page_status", value: res.data.page_status }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "events_loader", value: false }));
      console.log(err);
    });
};

export const deleteEvents = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_events_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}deleteEvents`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "update_events_loading", value: false }));
      if (res.data.status) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback();
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_events_loading", value: false }));
      console.log(err);
    });
};

export const getEventDetails = (id) => (dispatch) => {
  dispatch(updateRedux({ key: "detail_event_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}eventDetails/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_event_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "event_details", value: res.data.event_details })
        );
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_event_loading", value: false }));
      console.log(err);
    });
};

export const addEvents = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_events_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}addEvents`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_events_loading", value: false }));
      callback && callback(res.data);
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_events_loading", value: false }));
      console.log(err);
    });
};

export const updateEvents = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_events_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}updateEvents`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_events_loading", value: false }));
      callback && callback(res.data);
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_events_loading", value: false }));
      console.log(err);
    });
};

export const updateEventStatus = (id, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_events_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}updateEventStatus/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "update_events_loading", value: false }));
      if (callback) {
        callback(res.data);
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_events_loading", value: false }));
      console.log(err);
    });
};

export const exportEvents = (formData) => (dispatch) => {
  axios.post(`${API_BASE_URL}exportEvents`, formData, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${localStorage.getItem("token")}`
    },
  }).then((res) => {
    if (res.status == 200) {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        'export.xlsx',
      );
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getEventApplicants = (formData) => (dispatch) => {
  dispatch(updateRedux({ key: "events_loader", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}getEventApplicants`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "events_loader", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "events_applicants_details", value: res.data.event_details }));
        dispatch(updateRedux({ key: "events_applicants_page_status", value: res.data.page_status }));
        dispatch(updateRedux({ key: "events_applicants_pages", value: res.data.pages }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "events_loader", value: false }));
      console.log(err);
    });
};

export const exportEventsDetails = (id) => (dispatch) => {
  axios.get(`${API_BASE_URL}exportEventsDetails/${id}`, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${localStorage.getItem("token")}`
    },
  }).then((res) => {
    if (res.status == 200) {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        'export.xlsx',
      );
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getBenefits = (formData) => (dispatch) => {
  dispatch(updateRedux({ key: "benefits_loader", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}getBenefits`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "benefits_loader", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "benefits_data_list", value: res.data.benefits	 }));
        dispatch(updateRedux({ key: "benefits_data_pages", value: res.data.pages }));
        dispatch(updateRedux({ key: "benefits_data_page_status", value: res.data.page_status }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "benefits_loader", value: false }));
      console.log(err);
    });
};

export const deleteBenefit = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_benefits_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}deleteBenefit`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "update_benefits_loading", value: false }));
      if (res.data.status) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback();
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_benefits_loading", value: false }));
      console.log(err);
    });
};

export const getBenefitDetail = (id) => (dispatch) => {
  dispatch(updateRedux({ key: "detail_benefit_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getBenefitDetail/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_benefit_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "benefit_details", value: res.data.benefits })
        );
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_benefit_loading", value: false }));
      console.log(err);
    });
};

export const addBenefit = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_benefits_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}addBenefit`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_benefits_loading", value: false }));
      callback && callback(res.data);
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_benefits_loading", value: false }));
      console.log(err);
    });
};

export const updateBenefit = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_benefits_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}updateBenefit`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_benefits_loading", value: false }));
      callback && callback(res.data);
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_benefits_loading", value: false }));
      console.log(err);
    });
};

export const updateBenefitStatus = (id, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_benefits_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}updateBenefitStatus/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "update_benefits_loading", value: false }));
      if (callback) {
        callback(res.data);
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_benefits_loading", value: false }));
      console.log(err);
    });
};

export const exportBenefits = (formData) => (dispatch) => {
  axios.post(`${API_BASE_URL}exportBenefits`, formData, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${localStorage.getItem("token")}`
    },
  }).then((res) => {
    if (res.status == 200) {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        'export.xlsx',
      );
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getQuickLinks = (formData) => (dispatch) => {
  dispatch(updateRedux({ key: "quick_link_loader", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}getQuickLinks`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "quick_link_loader", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "quick_link_data_list", value: res.data.quicklinks_list	 }));
        dispatch(updateRedux({ key: "quick_link_data_pages", value: res.data.pages }));
        dispatch(updateRedux({ key: "quick_link_data_page_status", value: res.data.page_status }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "quick_link_loader", value: false }));
      console.log(err);
    });
};

export const deleteQuickLink = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_quick_link_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}deleteQuickLink`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "update_quick_link_loading", value: false }));
      if (res.data.status) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback();
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_quick_link_loading", value: false }));
      console.log(err);
    });
};

export const getQuickLinkDetail = (id) => (dispatch) => {
  dispatch(updateRedux({ key: "detail_quick_link_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getQuickLinkDetail/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_quick_link_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "quick_link_details", value: res.data.quicklink_details })
        );
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_quick_link_loading", value: false }));
      console.log(err);
    });
};

export const addQuickLink = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_quick_link_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}addQuickLink`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_quick_link_loading", value: false }));
      callback && callback(res.data);
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_quick_link_loading", value: false }));
      console.log(err);
    });
};

export const updateQuickLink = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_quick_link_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}updateQuickLink`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_quick_link_loading", value: false }));
      callback && callback(res.data);
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_quick_link_loading", value: false }));
      console.log(err);
    });
};

export const updateQuickLinkStatus = (id, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_quick_link_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}updateQuickLinkStatus/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "update_quick_link_loading", value: false }));
      if (callback) {
        callback(res.data);
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_quick_link_loading", value: false }));
      console.log(err);
    });
};

export const exportQuickLinks = (formData) => (dispatch) => {
  axios.post(`${API_BASE_URL}exportQuickLinks`, formData, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${localStorage.getItem("token")}`
    },
  }).then((res) => {
    if (res.status == 200) {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        'export.xlsx',
      );
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getFaqs = (formData) => (dispatch) => {
  dispatch(updateRedux({ key: "faq_loader", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}getFaqs`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "faq_loader", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "faq_data_list", value: res.data.faqs }));
        dispatch(updateRedux({ key: "faq_data_pages", value: res.data.pages }));
        dispatch(updateRedux({ key: "faq_data_page_status", value: res.data.page_status }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "faq_loader", value: false }));
      console.log(err);
    });
};

export const deleteFaq = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_faq_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}deleteFaq`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "update_faq_loading", value: false }));
      if (res.data.status) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback();
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_faq_loading", value: false }));
      console.log(err);
    });
};

export const getFaqDetail = (id) => (dispatch) => {
  dispatch(updateRedux({ key: "detail_faq_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getFaqDetail/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_faq_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "faq_details", value: res.data.faqs })
        );
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_faq_loading", value: false }));
      console.log(err);
    });
};

export const addFaq = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_faq_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}addFaq`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_faq_loading", value: false }));
      callback && callback(res.data);
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_faq_loading", value: false }));
      console.log(err);
    });
};

export const updateFaq = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_faq_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}updateFaq`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_faq_loading", value: false }));
      callback && callback(res.data);
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_faq_loading", value: false }));
      console.log(err);
    });
};

export const updateFaqStatus = (id, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_faq_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}updateFaqStatus/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "update_faq_loading", value: false }));
      if (callback) {
        callback(res.data);
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_faq_loading", value: false }));
      console.log(err);
    });
};

export const exportFaqs = (formData) => (dispatch) => {
  axios.post(`${API_BASE_URL}exportFaqs`, formData, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${localStorage.getItem("token")}`
    },
  }).then((res) => {
    if (res.status == 200) {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        'export.xlsx',
      );
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getPolicy = (formData) => (dispatch) => {
  dispatch(updateRedux({ key: "policy_loader", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}getPolicy`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "policy_loader", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "policy_data_list", value: res.data.policy_list	 }));
        dispatch(updateRedux({ key: "policy_data_pages", value: res.data.pages }));
        dispatch(updateRedux({ key: "policy_data_page_status", value: res.data.page_status }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "policy_loader", value: false }));
      console.log(err);
    });
};

export const deletePolicy = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_policy_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}deletePolicy`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "update_policy_loading", value: false }));
      if (res.data.status) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback();
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_policy_loading", value: false }));
      console.log(err);
    });
};

export const getPolicyDetail = (id) => (dispatch) => {
  dispatch(updateRedux({ key: "detail_policy_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getPolicyDetail/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_policy_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "policy_details", value: res.data.policy_details })
        );
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_policy_loading", value: false }));
      console.log(err);
    });
};

export const addPolicy = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_policy_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}addPolicy`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_policy_loading", value: false }));
      callback && callback(res.data);
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_policy_loading", value: false }));
      console.log(err);
    });
};

export const updatePolicy = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_policy_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}updatePolicy`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_policy_loading", value: false }));
      callback && callback(res.data);
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_policy_loading", value: false }));
      console.log(err);
    });
};

export const updatePolicyStatus = (id, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_policy_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}updatePolicyStatus/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "update_policy_loading", value: false }));
      if (callback) {
        callback(res.data);
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_policy_loading", value: false }));
      console.log(err);
    });
};

export const exportPolicy = (formData) => (dispatch) => {
  axios.post(`${API_BASE_URL}exportPolicy`, formData, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${localStorage.getItem("token")}`
    },
  }).then((res) => {
    if (res.status == 200) {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        'export.xlsx',
      );
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getGallery = (formData) => (dispatch) => {
  dispatch(updateRedux({ key: "gallery_loader", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}getGallery`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "gallery_loader", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "gallery_data_list", value: res.data.gallery_list	 }));
        dispatch(updateRedux({ key: "gallery_data_pages", value: res.data.pages }));
        dispatch(updateRedux({ key: "gallery_data_page_status", value: res.data.page_status }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "gallery_loader", value: false }));
      console.log(err);
    });
};

export const deleteGallery = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_gallery_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}deleteGallery`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "update_gallery_loading", value: false }));
      if (res.data.status) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback();
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_gallery_loading", value: false }));
      console.log(err);
    });
};

export const getGalleryDetail = (id) => (dispatch) => {
  dispatch(updateRedux({ key: "detail_gallery_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getGalleryDetail/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_gallery_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "gallery_details", value: res.data.gallery_details })
        );
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_gallery_loading", value: false }));
      console.log(err);
    });
};

export const addGallery = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_gallery_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}addGallery`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_gallery_loading", value: false }));
      callback && callback(res.data);
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_gallery_loading", value: false }));
      console.log(err);
    });
};

export const updateGallery = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_gallery_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}updateGallery`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_gallery_loading", value: false }));
      callback && callback(res.data);
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_gallery_loading", value: false }));
      console.log(err);
    });
};

export const updateGalleryStatus = (id, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_gallery_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}updateGalleryStatus/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "update_gallery_loading", value: false }));
      if (callback) {
        callback(res.data);
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_gallery_loading", value: false }));
      console.log(err);
    });
};

export const getCalenderEvents = (formData) => (dispatch) => {
  dispatch(updateRedux({ key: "calender_loader", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}getCalenderEvents`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "calender_loader", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "calender_data_list", value: res.data.events	 }));
        dispatch(updateRedux({ key: "calender_data_pages", value: res.data.pages }));
        dispatch(updateRedux({ key: "calender_data_page_status", value: res.data.page_status }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "calender_loader", value: false }));
      console.log(err);
    });
};

export const deleteCalenderEvent = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_calender_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}deleteCalenderEvent`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "update_calender_loading", value: false }));
      if (res.data.status) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback();
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_calender_loading", value: false }));
      console.log(err);
    });
};

export const getCalenderEventDetail = (id) => (dispatch) => {
  dispatch(updateRedux({ key: "detail_calender_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getCalenderEventDetail/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_calender_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "calender_details", value: res.data.event	 })
        );
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_calender_loading", value: false }));
      console.log(err);
    });
};

export const addCalenderEvent = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_calender_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}addCalenderEvent`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_calender_loading", value: false }));
      callback && callback(res.data);
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_calender_loading", value: false }));
      console.log(err);
    });
};

export const updateCalenderEvent = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_calender_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}updateCalenderEvent`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_calender_loading", value: false }));
      callback && callback(res.data);
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_calender_loading", value: false }));
      console.log(err);
    });
};

export const updateCalendarStatus = (id, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_calender_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}updateCalendarStatus/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "update_calender_loading", value: false }));
      if (callback) {
        callback(res.data);
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_calender_loading", value: false }));
      console.log(err);
    });
};

export const exportCalender = (formData) => (dispatch) => {
  axios.post(`${API_BASE_URL}exportCalender`, formData, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${localStorage.getItem("token")}`
    },
  }).then((res) => {
    if (res.status == 200) {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        'export.xlsx',
      );
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getSurveys = (formData) => (dispatch) => {
  dispatch(updateRedux({ key: "survey_loader", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}getSurveys`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "survey_loader", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "survey_data_list", value: res.data.survey_list }));
        dispatch(updateRedux({ key: "survey_data_pages", value: res.data.pages }));
        dispatch(updateRedux({ key: "survey_data_page_status", value: res.data.page_status }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "survey_loader", value: false }));
      console.log(err);
    });
};

export const addSurvey = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_survey_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}addSurvey`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_survey_loading", value: false }));
      callback && callback(res.data);
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_survey_loading", value: false }));
      console.log(err);
    });
};

export const updateSurvey = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_survey_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}updateSurvey`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_survey_loading", value: false }));
      callback && callback(res.data);
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_survey_loading", value: false }));
      console.log(err);
    });
};

export const updateSurveyStatus = (id, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_survey_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}updateSurveyStatus/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "update_survey_loading", value: false }));
      if (callback) {
        callback(res.data);
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_survey_loading", value: false }));
      console.log(err);
    });
};

export const exportSurvey = (formData) => (dispatch) => {
  axios.post(`${API_BASE_URL}exportSurvey`, formData, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${localStorage.getItem("token")}`
    },
  }).then((res) => {
    if (res.status == 200) {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        'export.xlsx',
      );
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const deleteSurvey = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_survey_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}deleteSurvey`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "update_survey_loading", value: false }));
      if (res.data.status) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback();
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_survey_loading", value: false }));
      console.log(err);
    });
};

export const getSurveyDetail = (id) => (dispatch) => {
  dispatch(updateRedux({ key: "detail_survey_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getSurveyDetail/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_survey_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "survey_details", value: res.data.survey_details })
        );
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_survey_loading", value: false }));
      console.log(err);
    });
};

export const surveyAnsweredUsers = (formData) => (dispatch) => {
  dispatch(updateRedux({ key: "detail_survey_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}surveyAnsweredUsers`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_survey_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "survey_answer_details", value: res.data.poll_question }));
        dispatch(updateRedux({ key: "survey_answer_pages", value: res.data.pages }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_survey_loading", value: false }));
      console.log(err);
    });
};

export const exportSurveyAnsweredQuestionAndUsers = (formData) => (dispatch) => {
  axios.post(`${API_BASE_URL}exportSurveyAnsweredQuestionAndUsers`, formData,{
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${localStorage.getItem("token")}`
    },
  }).then((res) => {
    if (res.status == 200) {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        'export.xlsx',
      );
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getWebTexts = (formData) => (dispatch) => {
  dispatch(updateRedux({ key: "translation_loader", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}getWebTexts`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "translation_loader", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "translation_web_data_list", value: res.data.translate_details }));
        dispatch(updateRedux({ key: "translation_web_data_pages", value: res.data.pages }));
        dispatch(updateRedux({ key: "translation_web_data_page_status", value: res.data.page_status }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "translation_loader", value: false }));
      console.log(err);
    });
};

export const insertWebText = (data, callback) => (dispatch) => {
  axiosInstance
    .post(`${API_BASE_URL}insertWebText`, data)
    .then((res) => {
      console.log(res.data, "res.data")
      callback && callback(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const updateWebText = (data, callback) => (dispatch) => {
  axiosInstance
    .post(`${API_BASE_URL}updateWebText`, data)
    .then((res) => {
      callback && callback(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const deleteWebText = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_translation_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}deleteWebText`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "update_translation_loading", value: false }));
      if (callback) {
        callback(res?.data);
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_translation_loading", value: false }));
      console.log(err);
    });
};

export const dashboardCounts  = () => (dispatch) => {
  dispatch(updateRedux({ key: "dashboard_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}dashboardCounts`)
    .then((res) => {
      dispatch(updateRedux({ key: "dashboard_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "dashboard_data", value: res.data })
        );
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "dashboard_loading", value: false }));
      console.log(err);
    });
};
