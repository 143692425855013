import React, { useEffect, useState } from "react";
import PageHeader from "../../commonComponents/PageHeader/PageHeader";
import Title from "../../commonComponents/Title/Title";
import BreadCrump from "../../commonComponents/Breadcrump/Breadcrump";
import useForm from "../../utils/Hooks/useForm";
import Button from "../../commonComponents/Button/Button";
import FooterButtons from "../../commonComponents/FooterButtons/FooterButtons";
import BackIcon from "../../assets/icons/Back.svg";
import { useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import Input from "../../commonComponents/Input/Input";
import TitleBar from "../../commonComponents/TitleBar/TitleBar";
import FileUpload from "../../commonComponents/FileUpload/FileUpload";
import {
  addQuickLink,
  getQuickLinkDetail,
  updateQuickLink,
  uploadFile,
} from "../../redux/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import RadioButtons from "../../commonComponents/RadioButtons/RadioButtons";
import {
  detail_quick_link_loading,
  fileUploadLoader,
  quick_link_details,
  update_quick_link_loading,
} from "../../redux/commonReducer";
import { IMAGE_BASE_URL } from "../../constants/configuration";
import { valueSplitFunction } from "../../utils/helpers";
import StatusModal from "../../commonComponents/StatusModal/StatusModal";
import useTranslate from "../../utils/Hooks/useTranslate";

export default function QuickLinksAdd() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslate();

  const loading = useSelector(detail_quick_link_loading);
  const details = useSelector(quick_link_details);
  const updateLoading = useSelector(update_quick_link_loading);
  const uploadLoading = useSelector(fileUploadLoader);

  const [successMessage, setSuccessMessage] = useState({
    message: "",
    title: "",
    isError: false,
  });
  const [showModal, setShowModal] = useState(false);
  const [addAttachmentsLoader, setAddAttachmentsLoader] = useState(false);
  const [invalidUpload, setInvalidUpload] = useState(false);
  const [trackId, setTrackId] = useState("");

  const {
    errors,
    inputHandler,
    inputs,
    formPlug,
    validate,
    setErrors,
    setInputs,
    clearInputs,
  } = useForm({});

  useEffect(() => {
    if (id) {
      dispatch(getQuickLinkDetail(id));
    }
  }, [id]);

  useEffect(() => {
    if (details && id) {
      let result = {
        title: details?.title,
        title_ar: details?.title_ar,
        url: details?.url,
        publish: details?.publish?.toString(),
      };
      if (details?.logo?.length) {
        result = {
          ...result,
          logo: [
            {
              type: details?.logo.split(".").pop(),
              url: `${IMAGE_BASE_URL}quickLinks/${details?.logo}`,
              name: valueSplitFunction(details?.logo),
              response: `${details?.logo}`,
            },
          ],
        };
      }
      setInputs({ ...result });
    }
  }, [details]);

  const successActionAfterApiResponse = (res) => {
    setSuccessMessage({
      title: id ? t("Update Quick Links") : t("Create Quick Links"),
      message: res.message,
    });
    setShowModal(true);
    setTimeout(() => {
      navigate(-1);
    }, 1000);
  };

  const errActionAfterApiResponse = (res) => {
    setSuccessMessage({
      title: t("Alert"),
      message: res.message,
      isError: true,
    });
    setShowModal(true);
    if (res?.data?.redirect) {
      setTimeout(() => {
        setShowModal(false);
        navigate(-1);
      }, 1000);
    } else {
      setTimeout(() => {
        setShowModal(false);
      }, 1000);
    }
  };

  const singleFileUploadHandler = (e, isInvalid, message, isClosing, field) => {
    if (isInvalid) {
      setInvalidUpload(true);
      setErrors({
        ...errors,
        [field]: message,
      });
    } else {
      setInvalidUpload(false);
      setErrors({
        ...errors,
        [field]: "",
      });
      if (e?.some((elem, i) => elem?.metaFile) && !isClosing) {
        setAddAttachmentsLoader(true);

        let formData = new FormData();
        if (trackId?.length == 0) {
          let track_id = Math.floor(1000 + Math.random() * 9000).toString();
          setTrackId(track_id);
          formData.append("track_id", track_id);
        } else {
          formData.append("track_id", trackId);
        }
        formData.append("file", e[0]?.metaFile);
        dispatch(
          uploadFile(formData, (res) => {
            setErrors({
              ...errors,
              [field]: "",
            });
            setInputs((s) => ({
              ...s,
              [field]: [
                {
                  type: e[0].type,
                  url: e[0].url,
                  name: e[0]?.name,
                  metaFile: e,
                  response: res?.data[0],
                },
              ],
            }));
            setAddAttachmentsLoader(false);
          })
        );
      }
    }
  };

  const onCancelHandler = () => {
    setErrors({});
    clearInputs();
    // navigate(-1);
  };

  const submitHandler = async () => {
    let isValidated = await validate();
    if (isValidated && !invalidUpload) {
      finalSubmitHandler();
    }
  };

  const finalSubmitHandler = () => {
    let formData = new FormData();
    formData.append("title", inputs?.title ?? "");
    formData.append("title_ar", inputs?.title_ar ?? "");
    formData.append("url", inputs?.url ?? "");
    formData.append("publish", inputs?.publish ?? "");
    formData.append("logo", inputs?.logo?.[0]?.response ?? "");
    formData.append("track_id", trackId);
    console.log(formData, "form");
    if (id) {
      formData.append("id", id);
      dispatch(
        updateQuickLink(formData, (res) => {
          if (res.status) {
            successActionAfterApiResponse(res);
          } else {
            errActionAfterApiResponse(res);
          }
        })
      );
    } else {
      dispatch(
        addQuickLink(formData, (res) => {
          if (res.status) {
            successActionAfterApiResponse(res);
          } else {
            errActionAfterApiResponse(res);
          }
        })
      );
    }
  };

  return (
    <div id="quick_links_addRequest">
      <section className="mb-4">
        <PageHeader>
          <Title content={id ? "Edit Quick Links" : "Add Quick Links"} />
          <BreadCrump
            content={[
              {
                name: id ? "Edit Quick Links" : "Add Quick Links",
              },
              {
                name: "Quick Links",
                path: "/quick_links/quick_links-list",
              },
              {
                name: "Home",
                path: "/",
              },
            ]}
          />
        </PageHeader>
      </section>
      {loading ? (
        <LoadingSpinner height={"200px"} />
      ) : (
        <>
          <TitleBar
            title={id ? "Edit Quick Links" : "Add Quick Links"}
            toggleButton
            defaultToggle={"show"}
            className={"css-tbr-esc1"}
          >
            <Input
              className="css-inp-esc1"
              label="Title in English"
              placeholder="Type here"
              required={{ message: t("Please enter title in english") }}
              field={"title"}
              value={inputs}
              error={errors}
              inputSocket={formPlug}
              onChange={inputHandler}
            />
            <Input
              className="css-inp-esc1"
              label="Title in Arabic"
              placeholder="Type here"
              required={{ message: t("Please enter title in english") }}
              // validation={{
              //   alphabetsOnly: { lang: "ar", more: '!@#$%^&*()_+-=><.,:;"' },
              // }}
              field={"title_ar"}
              value={inputs}
              error={errors}
              inputSocket={formPlug}
              onChange={inputHandler}
            />
            <Input
              className="css-inp-esc1"
              label="Url"
              placeholder="Type here"
              required={{ message: t("Please enter url") }}
              field={"url"}
              value={inputs}
              error={errors}
              inputSocket={formPlug}
              onChange={inputHandler}
            />
            <div>
              <div className="d-flex gap-3">
                <FileUpload
                  style={{
                    marginRight: 5,
                    marginTop: 15,
                  }}
                  className="css-atc-esc2  mt-3"
                  label="Upload Logo"
                  buttonLabel="Choose File"
                  accept={["jpg", "jpeg", "png", "svg"]}
                  onChange={(e, isInvalid, message, isClosing) => {
                    setErrors((s) => ({
                      ...s,
                      logo: "",
                    }));
                    singleFileUploadHandler(
                      e,
                      isInvalid,
                      message,
                      isClosing,
                      "logo"
                    );
                  }}
                  loader={addAttachmentsLoader}
                  error={errors.logo}
                  field={"logo"}
                  id="logo"
                  maxFileSizeMB={50}
                  multiple={false}
                  showPreview
                  files={inputs?.logo}
                  required={{ message: t("Please upload logo") }}
                  inputSocket={formPlug}
                />
              </div>
            </div>
            <div>
              <RadioButtons
                label={"Publish"}
                buttons={[
                  {
                    label: "Yes",
                    value: "1",
                  },
                  {
                    label: "No",
                    value: "0",
                  },
                ]}
                name="publish"
                placeholder={"Select any option"}
                required={{ message: t("Please select publish status") }}
                field={"publish"}
                error={errors.publish}
                value={{
                  publish: inputs?.publish,
                }}
                onChange={(value, key) => inputHandler(value, key)}
                inputSocket={formPlug}
              />
            </div>
          </TitleBar>

          <FooterButtons>
            <Button
              right
              className="css-btn-esc3"
              content="Back"
              icon={BackIcon}
              reverse
              iconWidth="15px"
              onClick={() => navigate(-1)}
            />
            <Button
              left
              className="css-btn-esc3"
              content="Submit"
              onClick={() => submitHandler()}
              loader={updateLoading || uploadLoading}
              disabled={updateLoading || uploadLoading}
            />
            <Button
              left
              className="css-btn-esc3"
              content="Cancel"
              loader={updateLoading || uploadLoading}
              disabled={updateLoading || uploadLoading}
              onClick={() => onCancelHandler()}
            />
          </FooterButtons>
        </>
      )}
      <StatusModal
        show={showModal}
        setShow={setShowModal}
        header={successMessage?.title}
        isError={successMessage?.isError}
      >
        {successMessage?.message}
      </StatusModal>
    </div>
  );
}
