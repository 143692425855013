import React from "react";
import "./PageHeader.scss";

export default function PageHeader({ children, style }) {
  return (
    <div id="PageHeader" style={style}>
      <div className="title">
        {children &&
          (!Array.isArray(children) ? [children] : children).filter((obj) =>
            ["Title", "Breadcrump"].includes(obj?.type?.displayName)
          )}
      </div>
      <div className="buttons">
        {children &&
          (!Array.isArray(children) ? [children] : children).filter((obj) =>
            ["Button", "StatusBox"].includes(obj?.type?.displayName)
          )}
      </div>
    </div>
  );
}
