import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import Title from "../../commonComponents/Title/Title";
import BreadCrump from "../../commonComponents/Breadcrump/Breadcrump";
import Button from "../../commonComponents/Button/Button";
import EntriesCounter from "../../commonComponents/EntriesCounter/EntriesCounter";
import Input from "../../commonComponents/Input/Input";
import Table from "../../commonComponents/Table/Table";
import Modal from "../../commonComponents/Modal/Modal";
import SearchBox from "../../commonComponents/SearchBox/SearchBox";
import Actions from "../../commonComponents/Actions/Actions";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import useTranslate from "../../utils/Hooks/useTranslate";
import PageHeader from "../../commonComponents/PageHeader/PageHeader";
import ModalContent from "../../commonComponents/ModalContent/ModalContent";
import useFilter from "../../utils/Hooks/useFilter";
import EditIcon from "../../assets/icons/Edit.svg";
import DeleteIcon from "../../assets/icons/delete.svg";
import AddRequestIcon from "../../assets/icons/Add Request.svg";
import GenericSearchIcon from "../../assets/icons/Search1.svg";
import {
  deletePermission,
  getAllUsers,
  getDepartments,
  getModules,
  getPermissionsList,
  getSection,
} from "../../redux/actionCreator";
import {
  all_users_list_wo_pagination,
  department_List,
  permission_list,
  permission_list_loading,
  permission_list_page_status,
  permission_list_pages,
  section_details,
  update_permission_loading,
  updateRedux,
} from "../../redux/commonReducer";

export default function ManageRoles() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslate();
  const lang = localStorage.getItem("lang");

  const permissionData = useSelector(permission_list);
  const loading = useSelector(permission_list_loading);
  const pageCount = useSelector(permission_list_pages);
  const pageStatus = useSelector(permission_list_page_status);
  const departmentList = useSelector(department_List);
  const sectionList = useSelector(section_details);
  const allUsersList = useSelector(all_users_list_wo_pagination);
  const updateLoading = useSelector(update_permission_loading);

  const initialFilter = {
    department: "",
    section: "",
    employee: "",
    keyword: "",
    page: 0,
    perPage: 10,
    sortColumn: "updated_at",
    sortValue: -1,
  };

  const [filterData, setFilterData, F_navigate] = useFilter({
    initialFilter,
    dataFetch: (filter) => {
      getSearchDataHandler(filter);
    },
  });

  const [data, setData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteID, setDeleteID] = useState("");

  useEffect(() => {
    dispatch(getModules());
    dispatch(getDepartments());
    let formData = new FormData();
    formData.append("for_filter", 1);
    dispatch(getAllUsers(formData));
  }, []);

  useEffect(() => {
    if (filterData?.department != "") {
      dispatch(getSection(filterData?.department?._id));
    } else {
      dispatch(updateRedux({ key: "section_details", value: [] }));
    }
  }, [filterData]);

  useEffect(() => {
    setData([]);
    if (permissionData) {
      permissionData?.forEach((item) => {
        setData((s) => [
          ...s,
          {
            employee_id: item?.employee_id,
            employee_name: item?.name,
            department: item?.department ? (
              lang == "en" ? (
                item?.department?.department_name
              ) : (
                item?.department?.department_name_ar
              )
            ) : (
              <span className="mr-5">--</span>
            ),
            section: item?.section[0]?.section_name ?? (
              <span className="mr-5">--</span>
            ),
            designation: item.designation?.designation_name || (
              <span className="mr-5">--</span>
            ),
            permissions: item?.permissions?.length ?? "--",
            action: (
              <Actions
                buttons={{
                  edit: EditIcon,
                  delete: DeleteIcon,
                }}
                onEdit={() => F_navigate(`/manage-roles/update/${item?._id}`)}
                onDelete={() => showDeletePopup(item?._id)}
              />
            ),
          },
        ]);
      });
    }
  }, [permissionData]);

  const showDeletePopup = (id) => {
    setShowDeleteModal(true);
    setDeleteID(id);
  };

  const titles = [
    {
      title: "Employee ID",
      key: "employee_id",
      sort: true,
      width: "11%",
    },
    {
      title: "Employee Name",
      key: "employee_name",
      sort: true,
      width: "100px",
    },
    {
      title: "Department",
      key: "department",
      sort: true,
      width: "200px",
    },
    {
      title: "Section",
      key: "section",
      sort: true,
      width: "200px",
    },
    {
      title: "Designation",
      key: "designation",
      sort: true,
      width: "90px",
    },
    {
      title: "Permission Count",
      key: "permissions",
      sort: false,
      width: "20px",
    },
    { title: "Action", key: "action", width: "85px" },
  ];

  const getSearchDataHandler = (filter) => {
    let formData = new FormData();
    formData.append("department", filter?.department?._id || "");
    formData.append("section", filter?.section?._id || "");
    formData.append("user", filter?.employee?._id || "");
    formData.append("keyword", filter?.keyword.trim() || "");
    formData.append("page", filter?.page);
    formData.append("perPage", filter?.perPage);
    formData.append("sort_order", filter?.sortValue);
    formData.append("sort_key", filter?.sortColumn);
    dispatch(getPermissionsList(formData));
  };

  function searchBoxChangeHandler(value, key, setValues, values) {
    if (key === "department") {
      setValues((s) => ({ ...s, employee: "", section: "" }));
      dispatch(updateRedux({ key: "section_details", value: [] }));
      value._id && dispatch(getSection(value._id));
      let formData = new FormData();
      formData.append("department", value?._id || "");
      formData.append("section", "");
      formData.append("for_filter", 1);
      dispatch(getAllUsers(formData));
    }
    if (key === "section") {
      setValues((s) => ({ ...s, employee: "" }));
      let formData = new FormData();
      formData.append("department", values?.department?._id || "");
      formData.append("section", value?._id || "");
      formData.append("for_filter", 1);
      dispatch(getAllUsers(formData));
    }
  }

  function filterHandler(key, value) {
    let filter;
    switch (key) {
      case "searchBox":
        filter = { ...filterData, ...value, page: 0 };
        break;
      case "clearSearch":
        filter = initialFilter;
        break;
      case "keyword":
        filter = { ...filterData, keyword: value, page: 0 };
        break;
      case "sort":
        filter = {
          ...filterData,
          sortColumn: value.key,
          sortValue: value.value,
        };
        break;
      case "page":
        filter = { ...filterData, page: value };
        break;
      case "entries":
        filter = { ...filterData, page: 0, perPage: parseInt(value) };
        break;
      default:
    }
    if (!filter) return;
    setFilterData(filter);
    getSearchDataHandler(filter);
  }

  const clearEmployeeList = () => {
    let formData = new FormData();
    formData.append("department", "");
    formData.append("section", "");
    formData.append("for_filter", 1);
    dispatch(getAllUsers(formData));
    dispatch(updateRedux({ key: "section_details", value: [] }));
  };

  const deleteHandler = () => {
    const value = {
      id: deleteID,
    };
    dispatch(
      deletePermission(value, () => {
        setShowDeleteModal(false);
        if (
          permissionData?.length == 1 &&
          pageCount > 1 &&
          filterData?.page != 0
        ) {
          setFilterData({
            ...filterData,
            page: filterData?.page - 1,
          });
          let formData = new FormData();
          formData.append("department", filterData?.department?._id || "");
          formData.append("section", filterData?.section?._id || "");
          formData.append("user", filterData?.employee?._id || "");
          formData.append("keyword", filterData?.keyword.trim() || "");
          formData.append("page", filterData?.page - 1);
          formData.append("perPage", filterData?.perPage);
          formData.append("sort_order", filterData?.sortValue);
          formData.append("sort_key", filterData?.sortColumn);
          dispatch(getPermissionsList(formData));
        } else {
          getSearchDataHandler(filterData);
        }
      })
    );
  };

  return (
    <div id="role">
      <>
        <section className="mb-4">
          <PageHeader>
            <Title content="Manage Permission" />
            <BreadCrump
              content={[
                {
                  name: "Manage Permission",
                  path: "",
                },
                { name: "Home", path: "/" },
              ]}
            />
            <Button
              className="css-btn-esc1"
              content="Add Permission"
              icon={AddRequestIcon}
              reverse
              onClick={() => navigate("/manage-roles/add")}
            />
          </PageHeader>
        </section>
        <section className="mb-4">
          <SearchBox
            elements={[
              {
                label: "Department",
                key: "department",
                type: "select",
                className: "css-slc-esc2",
                placeholder: "Select Department",
                width: "250px",
              },
              {
                label: "Section",
                key: "section",
                type: "select",
                className: "css-slc-esc2",
                placeholder: "Select Section",
                width: "200px",
              },
              {
                label: "Employee",
                key: "employee",
                type: "select",
                className: "css-slc-esc2",
                placeholder: "Select User",
                width: "200px",
              },
            ]}
            options={{
              department: {
                options: departmentList,
                value: filterData?.department,
                labelSetter: (option) =>
                  lang === "en"
                    ? option.department_name
                    : option.department_name_ar,
                valueSetter: (option) => option._id,
              },
              section: {
                options: sectionList,
                value: filterData?.section,
                labelSetter: (option) =>
                  lang === "en"
                    ? option?.section_name
                    : option?.section_name_ar,
                valueSetter: (option) => option._id,
              },
              employee: {
                options: allUsersList,
                labelSetter: (option) => option.name,
                valueSetter: (option) => option._id,
                value: filterData?.employee,
              },
            }}
            onSearch={(values) => {
              filterHandler("searchBox", values);
            }}
            onClear={() => {
              filterHandler("clearSearch");
              clearEmployeeList();
            }}
            onChange={searchBoxChangeHandler}
            dependency={[filterData]}
          />
        </section>
        <section className="d-flex justify-content-between mb-4">
          <EntriesCounter
            value={filterData.perPage}
            onChange={(e) => {
              filterHandler("entries", e);
            }}
          />
          <Input
            value={filterData.keyword}
            type="text"
            className="css-inp-esc2 search"
            icon={GenericSearchIcon}
            placeholder={t("Generic Search")}
            reverse
            width="300px"
            onSubmit={(value) => {
              filterHandler("keyword", value);
            }}
            onIconClick={(value) => {
              filterHandler("keyword", value);
            }}
            onChange={(value) => !value && filterHandler("keyword", value)}
            showCloseBtn
          />
        </section>
        {loading ? (
          <LoadingSpinner height={"300px"} />
        ) : (
          <section className="table">
            <Table
              className={"css-tbl-esc2"}
              titles={titles}
              content={data}
              onSort={(e) => filterHandler("sort", e)}
              sortValue={{
                key: filterData.sortColumn,
                value: filterData.sortValue,
              }}
              page={filterData.page}
              pagesCount={pageCount}
              pageStatus={pageStatus}
              onPageChange={(e) => {
                filterHandler("page", e.selected);
              }}
            />
          </section>
        )}
      </>
      <Modal show={showDeleteModal} setShow={() => setShowDeleteModal()}>
        <ModalContent header={t("Delete Role")}>
          <h3
            className={`${
              lang == "en" ? "langENDirectionStyle" : ""
            } right mb-3`}
          >
            {t("Are you sure to") + " " + t("delete") + " " + t("?")}
          </h3>
          <Button
            className="css-btn-esc3"
            onClick={deleteHandler}
            content={t("Yes")}
            disabled={updateLoading}
            loader={updateLoading}
          />
          <Button
            className="css-btn-esc3"
            onClick={() => setShowDeleteModal(false)}
            content={t("No")}
            disabled={updateLoading}
            loader={updateLoading}
          />
        </ModalContent>
      </Modal>
    </div>
  );
}
