import React, { useEffect, useRef, useState } from "react";
import "./EntriesCounter.scss";
import arrow from "./arrow.svg";
import useTranslate from "../../utils/Hooks/useTranslate";

export default function EntriesCounter({ value, onChange }) {
  const { t } = useTranslate();

  const [count, setCount] = useState(0);
  const intervalRef = useRef();

  useEffect(() => {
    value && setCount(value);
    return () => setCount(0);
  }, [value]);

  function changeHandler(e) {
    let value = parseInt(e.target.value || 0);
    if (value === count) return;
    if (value > 99) return;
    setCount(value);
    // onChange && onChange(value)
  }

  function blurHandler() {
    onChange && onChange(count);
  }

  function keyDownHandler(e) {
    if (e.key === "Enter") {
      onChange && onChange(count);
    }
  }

  function clickHandler(value) {
    if ((count === 0 && value === -1) || (count === 99 && value === 1)) return;
    setCount((s) => {
      let result = s + value;
      onChange && onChange(result);
      return result;
    });
  }

  // function holdHandler(value) {
  //   if (intervalRef.current) return
  //   intervalRef.current = setInterval(() => {
  //     clickHandler(value)
  //   }, 150);
  // }

  // function leaveHolder() {
  //   if (intervalRef.current) {
  //     clearInterval(intervalRef.current)
  //     intervalRef.current = null
  //   }
  // }

  return (
    <span id="EntriesCounter">
      <p>{t("Show")}</p>
      <span>
        <input
          dir="ltr"
          type="number"
          min={0}
          value={count}
          onChange={changeHandler}
          onKeyDown={keyDownHandler}
          onBlur={blurHandler}
        />
        <i>
          <img
            src={arrow}
            onClick={() => clickHandler(1)}
            // onMouseDown={() => holdHandler(1)} onMouseUp={leaveHolder} onMouseLeave={leaveHolder}
          />
          <img
            src={arrow}
            onClick={() => clickHandler(-1)}
            // onMouseDown={() => holdHandler(-1)} onMouseUp={leaveHolder} onMouseLeave={leaveHolder}
          />
        </i>
      </span>
      <p>{t("entries")}</p>
    </span>
  );
}
