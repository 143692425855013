import React, { useEffect, useState } from "react";
import "./Input.scss";
import { v4 } from "uuid";
import useTranslate from "../../utils/Hooks/useTranslate";

let validationObj = {
  minChar: { message: "", condition: "", length: "" },
  maxChar: { message: "", condition: "", length: "" },
  minNumber: { message: "", condition: "", number: "" },
  maxNumber: { message: "", condition: "", number: "" },
  email: { message: "", condition: "" },
  alphabetsOnly: { message: "", condition: "", more: "", lang: "" },
  alphaNumeric: { message: "", condition: "", more: "", lang: "" },
  phone: { message: "", condition: "", digits: "" },
};

export default function Input({
  type,
  label,
  value,
  field,
  onChange,
  onSubmit,
  onIconClick,
  placeholder,
  inputClassName,
  disabled,
  reverseFixed,
  required,
  icon,
  className,
  reverse,
  width,
  height,
  error,
  checked,
  showCloseBtn,
  fixed,
  dependency = [],
  inputSocket,
  validation = validationObj,
  maxNumber,
  minNumber,
  readOnly,
  dir,
  onBlur,
  span,
  condition,
  clearOnHide,
  fixedLtr,
  dontTranslate,
  onMouseLeave,
  maxDecimals,
}) {
  let uuid = v4();
  const { t } = useTranslate();

  const [inValue, setInValue] = useState("");
  const [inLabel, setInLabel] = useState("");
  const [inPlaceholder, setInPlaceholder] = useState("");
  const [inError, setInError] = useState("");

  useEffect(() => {
    setInValue(() => {
      let res = "";
      if (typeof value === "object" && value?.[field] !== undefined)
        res = value?.[field];
      else if (typeof value === "object" && value?.[field] === undefined)
        res = "";
      else if (value !== undefined) res = value;
      return res;
    });
    placeholder && setInPlaceholder(placeholder);
    label && setInLabel(label);
    return () => {
      setInValue("");
      setInPlaceholder("");
      setInLabel("");
    };
  }, [value, value?.[field], placeholder, label, ...dependency]);

  useEffect(() => {
    if (condition === undefined || condition === true) {
      let obj = { key: field };
      if (required) obj.required = required;
      if (ifObjectExist(validation.email)) obj.email = validation.email;
      if (ifObjectExist(validation.phone)) obj.phone = validation.phone;
      if (ifObjectExist(validation.minChar)) obj.minChar = validation.minChar;
      if (ifObjectExist(validation.maxChar)) obj.maxChar = validation.maxChar;
      if (ifObjectExist(validation.minNumber))
        obj.minNumber = validation.minNumber;
      if (ifObjectExist(validation.maxNumber))
        obj.maxNumber = validation.maxNumber;
      if (ifObjectExist(validation.alphabetsOnly))
        obj.alphabetsOnly = validation.alphabetsOnly;
      if (ifObjectExist(validation.alphaNumeric))
        obj.alphaNumeric = validation.alphaNumeric;
      inputSocket && inputSocket(obj);
      if (Object.keys(obj).length > 1 && !inputSocket)
        setInError("Please connect 'formPlug' to 'inputSocket'");
    }
    return () => {
      inputSocket && inputSocket({ key: field, clearValidation: true });
    };
  }, [
    ...(required && typeof required === "object"
      ? Object.values(required)
      : [required]),
    condition,
  ]);

  useEffect(() => {
    return () => {
      condition &&
        clearOnHide &&
        inputSocket &&
        inputSocket({ key: field, clearValidation: true, clearValue: true });
    };
  }, [condition]);

  function ifObjectExist(obj = {}) {
    return Object.values(obj).join("").length > 0 || obj === true;
  }

  function changeHandler(e) {
    let value =
      maxNumber > 0 && e.target.value > maxNumber ? maxNumber : e.target.value;
    value = e.target.value
      ? minNumber > 0 && e.target.value < minNumber
        ? minNumber
        : e.target.value
      : e.target.value;
    if (type === "number" && maxDecimals) {
      let decimals = String(value).split(".")[1];
      value = String(
        Number(value).toFixed(
          decimals?.length > maxDecimals ? maxDecimals : decimals?.length
        )
      );
    }
    setInValue(value);
    if (onChange) {
      type === "checkbox"
        ? onChange(e.target.checked, field)
        : onChange(value, field);
    }
  }

  function keyDownHandler(e) {
    if (e.key === "Enter") onSubmit && onSubmit(inValue, field);
  }

  function classNameFormatter(type, className, reverse) {
    let str = "";
    // str = type ? type : str
    str = className ? className : str;
    str = reverse ? str + " reverse" : str;
    return str;
  }

  function onWheelHandler(e) {
    if (type?.toLowerCase() !== "number") return;
    e.target.blur();
    e.stopPropagation();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  }

  if (!condition && condition !== undefined) return null;

  return (
    <span
      id="Input"
      className={classNameFormatter(type, className, reverse)}
      onClick={(e) => e.stopPropagation()}
    >
      {inLabel && (
        <label htmlFor={uuid}>
          {dontTranslate ? inLabel : t(inLabel)}
          {(required?.condition !== undefined
            ? required?.condition
            : required) && <em>*</em>}
        </label>
      )}
      <span className={"wrapper"}>
        <span
          className={
            "wrapper2" +
            (fixed ? " has-fixed-value" : "") +
            (reverseFixed ? " reverse" : "")
          }
          style={{ height: height }}
        >
          {fixed && (
            <span
              className="fixed-value"
              style={{ direction: fixedLtr ? "ltr" : "" }}
            >
              {fixed}
            </span>
          )}
          <input
            id={uuid}
            type={type && type}
            className={inputClassName}
            value={inValue}
            checked={checked && checked}
            placeholder={dontTranslate ? inPlaceholder : t(inPlaceholder)}
            style={{ width: width }}
            onChange={(e) => changeHandler(e)}
            onKeyDown={keyDownHandler}
            disabled={disabled}
            onWheel={onWheelHandler}
            readOnly={readOnly}
            dir={dir}
            onBlur={(e) => onBlur && onBlur(e.target.value, field)}
            onMouseLeave={(e) =>
              onMouseLeave && onMouseLeave(e.target.value, field)
            }
          />
        </span>
        {icon && (
          <img
            src={icon}
            onClick={() => onIconClick && onIconClick(inValue, field)}
            style={{ cursor: onIconClick ? "pointer" : "auto" }}
          />
        )}
        {showCloseBtn && inValue && (
          <span
            className="close_btn"
            onClick={() => {
              setInValue("");
              onChange && onChange("", field);
            }}
          >
            ✖
          </span>
        )}
      </span>
      {(inError || (typeof error === "object" ? error?.[field] : error)) && (
        <small id="error" className="error">
          {inError ||
            (typeof error === "object" ? t(error?.[field] || "") : t(error))}
        </small>
      )}
    </span>
  );
}

{
  /*

<Input
  label=''
  type=''
  value=''
  field=''
  onChange={() => { }}
  onSubmit={() => { }}
  onIconClick={() => { }}
  placeholder=''
  icon=''
  className=''
  reverse
  width=''
  height=''
  error=''
  checked=''
  showCloseBtn
/>

*/
}
