import React, { useEffect, useState } from "react";
import "./SearchBox.scss";
import Select from "../Select/Select";
import DatePick from "../DatePick/DatePick";
import Button from "../Button/Button";
import UploadIcon from "../../assets/icons/upload_export.svg";
import Input from "../Input/Input";

export default function SearchBox({
  elements,
  options,
  onChange,
  onSearch,
  onClear,
  dependency = [],
  AdvancedSearch,
  className,
  onExport,
  children,
  minWidth,
}) {
  const [state, setState] = useState({});

  useEffect(() => {
    let obj = {};
    Object.keys(options).forEach((key) => {
      options?.[key]?.value &&
        Object.assign(obj, { [key]: options?.[key]?.value });
    });
    setState(obj);
  }, [...dependency]);

  function onChangeHandler(value, key) {
    let res = { ...state, [key]: value };
    setState(res);
    onChange && onChange(value, key, setState, state);
  }

  function clearHandler() {
    setState({});
    onClear && onClear();
  }

  return (
    <div id="SearchBox" className={className}>
      <div className={"inputs" + (onExport ? " mt-4" : "")}>
        {elements &&
          elements.map((element, index) => {
            if (!element?.condition && element?.condition !== undefined)
              return null;
            return element.type === "select" ? (
              <Select
                key={index}
                className={element.className}
                title={element.label}
                placeholder={element.placeholder}
                style={{ width: element.width, minWidth: element.minWidth }}
                options={options[element.key]?.options}
                labelSetter={options[element.key]?.labelSetter}
                valueSetter={options[element.key]?.valueSetter}
                onChange={(e) => onChangeHandler(e, element.key)}
                value={state?.[element.key] || ""}
                showCloseBtn
                isMulti={element.isMulti}
                required={element?.required}
                inputSocket={element?.formPlug}
                error={element?.error}
                field={element?.field}
                doTranslate={element?.doTranslate}
              />
            ) : element.type === "datePicker" ? (
              <DatePick
                key={index}
                className={element.className}
                title={element.label}
                placeholder={element.placeholder}
                // style={{ width: element.width }}
                selected={state?.[element.key] || ""}
                onChange={(date) => onChangeHandler(date, element.key)}
                filterTime={options[element.key]?.filterTime}
                dateFormat={options[element.key]?.dateFormat}
                maxDate={options[element.key]?.maxDate}
                minDate={options[element.key]?.minDate}
                showCloseBtn
                required={element?.required}
                inputSocket={element?.formPlug}
                error={element?.error}
                field={element?.field}
                showMonthDropdown
                showYearDropdown
              />
            ) : element.type === "input" ? (
              <Input
                key={index}
                className={element.className}
                label={element.label}
                placeholder={element.placeholder}
                value={state?.[element.key] || ""}
                style={{ width: element.width }}
                onChange={(e) => onChangeHandler(e, element.key)}
                showCloseBtn
                required={element?.required}
                inputSocket={element?.formPlug}
                error={element?.error}
                field={element?.field}
              />
            ) : null;
          })}
        {children}
        <span>
          {onSearch && (
            <Button
              className="css-btn-esc2 search_button"
              content={"Search"}
              onClick={() => onSearch && onSearch(state)}
            />
          )}
          {Object.values(state).some((i) => i) && (
            <Button
              className="css-btn-esc2 sm me-2 close_button"
              onClick={() => clearHandler()}
              content="✖"
            />
          )}
        </span>
        {AdvancedSearch}
      </div>
      {onExport && (
        <Button
          className="css-btn-esc4 export_btn"
          iconWidth={12}
          content={"Export"}
          icon={UploadIcon}
          reverse
          onClick={() => onExport()}
        />
      )}
    </div>
  );
}

{
  /*

<SearchBox
  elements={[
    { label: 'Date', key: 'start_date', type: 'datePicker', className: 'css-dtp-esc1', placeholder: 'From' },
    { label: '', key: 'end_date', type: 'datePicker', className: 'css-dtp-esc1', placeholder: 'To' },
    { label: 'Request Type', key: 'request_type', type: 'select', className: 'css-slc-esc1', placeholder: 'Select', width: '200px' },
    { label: 'Department ', key: 'department', type: 'select', className: 'css-slc-esc1', placeholder: 'Department' },
    { label: 'Employee ', key: 'user', type: 'select', className: 'css-slc-esc1', placeholder: 'Select User', width: '200px' },
    { label: 'Status ', key: 'status', type: 'select', className: 'css-slc-esc1', placeholder: 'Select' },
  ]}
  options={{
    start_date: { selected: filterData?.start_date, filterTime: filterPassedTime, maxDate: filterData?.end_date ? filterData?.end_date : new Date(), dateFormat: "dd/MM/yy" },
    end_date: { selected: filterData?.end_date, filterTime: filterPassedTime, minDate: filterData?.start_date && filterData?.start_date, maxDate: new Date(), dateFormat: "dd/MM/yy" },
    request_type: { options: requestTypeList, value: filterData?.request_type, labelSetter: (option) => option.request_type, valueSetter: (option) => option._id },
    department: { options: departmentList, value: filterData?.department, labelSetter: (option) => option.department_name, valueSetter: (option) => option._id },
    user: { options: userList, labelSetter: (option) => option.name, valueSetter: (option) => option._id, value: filterData?.user },
    status: { options: requestStatus, labelSetter: (option) => option.request_status, valueSetter: (option) => option._id, value: filterData?.status, },
  }}
  showClearBtn={Object.values(filterData).some(e => Boolean(e))}
  showAdvancedSearch
  onSearch={applySearch}
  onClear={clearFilter}
  onExport={handleExport}
  onChange={searchBoxChangeHandler}
/>

*/
}
